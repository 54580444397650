import React from 'react';
import PropTypes from 'prop-types';
import { SdfButton, SdfIcon } from '@waypoint/react-components';
import { injectIntl } from 'react-intl';
import { getLocaleObjFromPropKey } from '../../util/locale';

class NotaClientImagePanel extends React.Component {
  onNotaClientRedirect = () => {
    const { linkUrl, redirectURL, locale } = this.props;

    redirectURL(getLocaleObjFromPropKey(linkUrl, locale), true);
  };

  render() {
    const {
      headlineText, bodyText, linkText, imageUrl, locale, intl,
    } = this.props;

    const headlineTextLocale = headlineText && getLocaleObjFromPropKey(headlineText, locale);
    const bodyTextLocale = bodyText && getLocaleObjFromPropKey(bodyText, locale);
    const linkTextLocale = linkText && getLocaleObjFromPropKey(linkText, locale);

    return (
      <div className="mt-2 flex">
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
        <div>
          <img
            id="imageQR"
            src={ imageUrl }
            height="105"
            width="105"
            alt="QRCodeImage"
          />
        </div>
        <div className="ml-3">
          { headlineTextLocale && (
            <h3
              id="clientHead"
              className="mb-2 mt-1 text-lg font-bold"
            >
              { headlineTextLocale }
            </h3>
          ) }
          { bodyTextLocale && (
            <span
              id="clientbody"
              className="max-w-md inline-block text-sm"
            >
              { bodyTextLocale }
            </span>
          ) }
          <br />
          { linkTextLocale && (
            <SdfButton
              id="learnMoreLink"
              onClick={ this.onNotaClientRedirect }
              target="_blank"
              emphasis="tertiary"
              variant="text"
              className="mt-2 text-md font-medium"
              button-title={ linkTextLocale }
            >
              <span id="learnMoreLinkMessage">
                { linkTextLocale }
                <SdfIcon
                  icon="nav-move-right"
                  className="ml-2"
                  icon-title={ intl.formatMessage({
                    id: 'mobile_app_label',
                    defaultMessage: 'Go to ADP Mobile App download page',
                  }) }
                />
              </span>
            </SdfButton>
          ) }
        </div>
      </div>
    );
  }
}

NotaClientImagePanel.propTypes = {
  headlineText: PropTypes.shape(PropTypes.string.isRequired),
  bodyText: PropTypes.shape(PropTypes.string.isRequired),
  linkText: PropTypes.shape(PropTypes.string.isRequired),
  linkUrl: PropTypes.shape(PropTypes.string.isRequired),
  imageUrl: PropTypes.string,
  redirectURL: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(NotaClientImagePanel);
