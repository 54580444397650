import React from 'react';
import PropTypes from 'prop-types';
import { SdfButton, SdfIcon } from '@waypoint/react-components';
import { injectIntl } from 'react-intl';
import { getLocaleObjFromPropKey } from '../../util/locale';

class NotaClientPanel extends React.Component {
  onNotaClientRedirect = () => {
    const { linkUrl, redirectURL, locale } = this.props;

    redirectURL(getLocaleObjFromPropKey(linkUrl, locale), true);
  };

  render() {
    const {
      headlineText, bodyText, linkText, locale, intl,
    } = this.props;

    const headlineTextLocale = headlineText && getLocaleObjFromPropKey(headlineText, locale);
    const bodyTextLocale = bodyText && getLocaleObjFromPropKey(bodyText, locale);
    const linkTextLocale = linkText && getLocaleObjFromPropKey(linkText, locale);

    return (
      <>
        { headlineTextLocale && (
          <h3
            id="clientHead"
            className="font-bold text-lg my-2"
          >
            { headlineTextLocale }
          </h3>
        ) }
        { bodyTextLocale && (
          <div
            id="clientbody"
            className="text-sm"
          >
            { bodyTextLocale }
          </div>
        ) }
        { linkTextLocale && (
          <SdfButton
            id="learnMoreLink"
            onClick={ this.onNotaClientRedirect }
            target="_blank"
            emphasis="tertiary"
            variant="text"
            className="text-md font-medium mt-2"
            button-title={ linkTextLocale }
          >
            <span id="learnMoreLinkMessage">
              { linkTextLocale }
              <SdfIcon
                icon="nav-move-right"
                icon-title={ intl.formatMessage({
                  id: 'mobile_app_label',
                  defaultMessage: 'Go to ADP Mobile App download page',
                }) }
                className="ml-2"
              />
            </span>
          </SdfButton>
        ) }
      </>
    );
  }
}

NotaClientPanel.propTypes = {
  headlineText: PropTypes.shape(PropTypes.string.isRequired),
  bodyText: PropTypes.shape(PropTypes.string.isRequired),
  linkText: PropTypes.shape(PropTypes.string.isRequired),
  linkUrl: PropTypes.shape(PropTypes.string.isRequired),
  redirectURL: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(NotaClientPanel);
