/* eslint-disable no-use-before-define */
import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SdfButton } from '@waypoint/react-components';
import { constants } from './channel-collection-constants';

import {
  isNotBlank,
  isBlank,
  isValidEmail,
  isOnlyBusinessEmailPresent,
  isOnlyPersonalEmailPresent,
  isMobileCollection,
  isMobileVerification,
  isEmailCollection,
  isEmailVerification,
  isMobileAndEmailActivation,
  isEnterKeyPressed,
} from '../../util/misc';
import {
  ChannelCollectionEmail,
  PasscodePageLayout,
  ChannelCollectionError,
  ChannelCollectionMobile,
} from '.';

const {
  MOBILE, EMAIL, PERSONAL, BUSINESS,
} = constants;

const ChannelCollectionForm = (props) => {
  const {
    saveCommunicationChannel,
    currentlySending,
    channelCollectionFailed,
    channelCollectionPassed,
    skipChannelCollection,
    mobile,
    consent,
    channelCollectionChallenge,
    emailAddress,
    emailUsageType,
    showEmailValidationError,
    persoanlEmailCommunicationId,
    personalPreferenceType,
    businessEmailCommunicationId,
    businessPreferenceType,
    palErrorMessage,
    mobileValid,
    isAppView,
  } = props;

  const { communicationCollectionType, communications } = channelCollectionChallenge;

  const onSave = () => {
    const addedCommunications = [];
    const changedCommunications = [];

    if (isMobileCollection(communicationCollectionType)) {
      mobile &&
        isNotBlank(mobile.mobileNumber) &&
        addedCommunications.push({
          type: MOBILE,
          countryCode: mobile && mobile.countryCode,
          mobileNumber: mobile && mobile.mobileNumber,
          usageType: PERSONAL,
          consentStatus: consent,
          country: mobile && mobile.country,
        });
    } else if (isMobileVerification(communicationCollectionType)) {
      mobile &&
        isNotBlank(mobile.mobileNumber) &&
        changedCommunications.push({
          type: MOBILE,
          countryCode: mobile.countryCode,
          mobileNumber: mobile.mobileNumber,
          usageType: PERSONAL,
          consentStatus: consent,
          country: mobile.country,
          communicationId: mobile.communicationId,
        });
    }

    if (isEmailCollection(communicationCollectionType)) {
      emailAddress &&
        emailUsageType &&
        addedCommunications.push({
          type: EMAIL,
          address: emailAddress,
          usageType: emailUsageType,
        });
    } else if (isEmailVerification(communicationCollectionType) && emailUsageType === PERSONAL) {
      if (isOnlyBusinessEmailPresent(communications)) {
        emailAddress &&
          emailUsageType &&
          addedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
          });
      } else {
        emailAddress &&
          emailUsageType &&
          changedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
            preferenceType: personalPreferenceType || businessPreferenceType,
            communicationId: persoanlEmailCommunicationId || businessEmailCommunicationId,
          });
      }
    } else if (isEmailVerification(communicationCollectionType) && emailUsageType === BUSINESS) {
      if (isOnlyPersonalEmailPresent(communications)) {
        emailAddress &&
          emailUsageType &&
          addedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
          });
      } else {
        emailAddress &&
          emailUsageType &&
          changedCommunications.push({
            type: EMAIL,
            address: emailAddress,
            usageType: emailUsageType,
            preferenceType: businessPreferenceType || personalPreferenceType,
            communicationId: businessEmailCommunicationId || persoanlEmailCommunicationId,
          });
      }
    }

    if (!isValidEmail(emailAddress)) {
      showEmailValidationError('channelcollection_invalid_email_address_label');
    } else {
      saveCommunicationChannel(addedCommunications, changedCommunications);
    }
  };

  const isSaveButtonDisabled =
    isBlank(emailAddress) ||
    isNotBlank(palErrorMessage) ||
    isBlank(mobile.mobileNumber) ||
    !mobileValid ||
    currentlySending;

  const handleVerify = () => {
    !isSaveButtonDisabled && onSave();
  };

  const handleVerifyButtonClick = () => {
    handleVerify();
  };

  const handleVerifyButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleVerify();
    }
  };

  const handleRemineMe = () => {
    !currentlySending && skipChannelCollection(true);
  };

  const handleRemindMeButtonClick = () => {
    handleRemineMe();
  };

  const handleRemindMeButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleRemineMe();
    }
  };

  return (
    (channelCollectionFailed && <ChannelCollectionError { ...props } />) ||
    (channelCollectionPassed && <PasscodePageLayout { ...props } />) || (
      <div id="channel-collection-combined">
        {
          <div className="font-medium md:font-normal leading-md mt-2 md:mt-4 mb-4">
            { isMobileAndEmailActivation(communicationCollectionType) ? (
              <FormattedMessage
                id="channelcollection_verify_combined"
                // eslint-disable-next-line max-len
                defaultMessage="To help protect your account we need to confirm your contact info. We will send you a text message to verify your mobile number."
              />
            ) : (
              <FormattedMessage
                id="channelcollection_combined_primary"
                // eslint-disable-next-line max-len
                defaultMessage="Protect your account from malicious activity by providing your primary mobile number and email address."
              />
            ) }
          </div>
        }
        <div className="flex flex-row justify-center">
          <div className={ isAppView ? 'w-full' : 'max-w-xs w-full md:max-w-full md:mx-20' }>
            <ChannelCollectionMobile
              { ...props }
              handleKeyPress={ handleVerifyButtonKeyPress }
            />

            <ChannelCollectionEmail
              { ...props }
              handleKeyPress={ handleVerifyButtonKeyPress }
            />
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center md:justify-end mt-4 md:mt-10 gap">
          <SdfButton
            id="channel-collection-save"
            emphasis="primary"
            size="lg"
            className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
            disabled={ isSaveButtonDisabled || undefined }
            onClick={ handleVerifyButtonClick }
            onKeyPress={ handleVerifyButtonKeyPress }
          >
            <FormattedMessage
              id="channelcollection_verify_number_button_label"
              defaultMessage="Verify mobile number"
            />
          </SdfButton>

          <SdfButton
            id="channel-collection-remind"
            emphasis="secondary"
            size="lg"
            className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
            disabled={ currentlySending || undefined }
            onClick={ handleRemindMeButtonClick }
            onKeyPress={ handleRemindMeButtonKeyPress }
          >
            <FormattedMessage
              id="channelcollection_remindme_button_label"
              defaultMessage="Remind me later"
            />
          </SdfButton>
        </div>
      </div>
    )
  );
};

ChannelCollectionForm.propTypes = {
  saveCommunicationChannel: PropTypes.func,
  currentlySending: PropTypes.bool,
  channelCollectionFailed: PropTypes.bool,
  channelCollectionPassed: PropTypes.bool,
  channelCollectionChallenge: PropTypes.shape({
    communicationCollectionType: PropTypes.string,
    communications: PropTypes.string,
  }),
  skipChannelCollection: PropTypes.func,
  consent: PropTypes.string,
  emailAddress: PropTypes.string,
  emailUsageType: PropTypes.string,
  showEmailValidationError: PropTypes.func,
  persoanlEmailCommunicationId: PropTypes.string,
  personalPreferenceType: PropTypes.string,
  businessEmailCommunicationId: PropTypes.string,
  businessPreferenceType: PropTypes.string,
  palErrorMessage: PropTypes.string,
  mobileValid: PropTypes.bool,
  mobile: PropTypes.shape({
    country: PropTypes.string,
    communicationId: PropTypes.string,
    countryCode: PropTypes.string,
    mobileNumber: PropTypes.string,
  }),
  isAppView: PropTypes.bool,
};

ChannelCollectionForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default ChannelCollectionForm;
