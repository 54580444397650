/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable react/prefer-stateless-function */
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import { SdfBusyIndicator } from '@waypoint/react-components';
import {
  isLocalStorageSupported,
  getCallingAppId,
  getUrlLocationSearch,
  getParameterCaseInsensitive,
} from '../util/misc';

import { LoginContainer } from '../containers';
import {
  HOME,
  STEP_UP_CHANNELS,
  QUESTION_COLLECTION,
  CHANNEL_COLLECTION,
  MOBILE_COLLECTION,
  LANDLINE_COLLECTION,
  EMAIL_COLLECTION,
  VOICE_BIOMETRICS_COLLECTION,
  STEP_UP_VERIFICATION,
  CHANGE_PASSWORD,
  TERMS_AND_CONDITIONS,
  STEP_UP_QNA,
  ERROR_PAGE,
  MOBILE_LIST_PAGE,
  SUCCESS_PAGE,
  FIDO_SETUP_CANCEL_PAGE,
  FIDO_SETUP_ERROR_PAGE,
  FIDO_AUTHENTICATE_SUCCESS,
  MESSAGE_SENT_PAGE,
  FIDO_REGISTER_SUCCESS,
  FIDO_TEXTLINK_PAGE,
  FIDO_DEVICE_REGISTER,
  FIDO_DEVICE_LIST,
} from './router-url-constants';

const ChangePasswordContainer = lazy(() => import('../containers/login/changepassword/ChangePasswordContainer'));
const ChannelCollectionContainer = lazy(() => import('../containers/login/channel-collection/ChannelCollectionContainer'));
const MobileCollectionContainer = lazy(() => import('../containers/login/channel-collection/MobileCollectionContainer'));
const LandlineCollectionContainer = lazy(() => import('../containers/login/channel-collection/LandlineCollectionContainer'));
const EmailCollectionContainer = lazy(() => import('../containers/login/channel-collection/EmailCollectionContainer'));
const VoiceBioMetricsCollectionContainer = lazy(() => import('../containers/login/channel-collection/VoiceBioMetricsCollectionContainer'));
const QuestionCollectionContainer = lazy(() => import('../containers/login/question-collection/QuestionCollectionContainer'));
const ErrorPageContainer = lazy(() => import('../containers/login/error/ErrorPageContainer'));
const CommonDisplayPageContainer = lazy(() => import('../containers/login/common-display/CommonDisplayPageContainer'));
const TCContainer = lazy(() => import('../containers/login/terms-and-conditions/TCContainer'));
const ChannelListContainer = lazy(() => import('../containers/login/step-up/ChannelListContainer'));
const OTPContainer = lazy(() => import('../containers/login/step-up/OTPContainer'));
const QNAContainer = lazy(() => import('../containers/login/step-up/QNAContainer'));
const MobileListContainer = lazy(() => import('../containers/login/otp-pwdless/MobileListContainer'));

const loading = (
  <SdfBusyIndicator
    size="xl"
    class="flex items-center justify-center h-full p-8 flex-col gap-8 relative"
  />
);

class Routes extends React.Component {
  componentDidMount() {
    window.onpopstate = () => {
      const { redirectURL } = this.props;

      redirectURL(`${HOME}/${getUrlLocationSearch()}`);
    };

    const callingAppId = getCallingAppId().toUpperCase();

    if (
      window.env &&
      (window.env.DISABLE_GLANCE_COBROWSE ?
        window.env.DISABLE_GLANCE_COBROWSE === 'false' ||
          window.env.DISABLE_GLANCE_COBROWSE === false :
        true) &&
      ((window.env.COBROWSE_APPLIST !== undefined &&
        window.env.COBROWSE_APPLIST.indexOf(callingAppId) !== -1) ||
        window.env.COBROWSE_APPLIST === undefined)
    ) {
      import('../util/html/fetchIndexHtmlResources')
        .then(({ loadCobrowse }) => {
          loadCobrowse();
        })
        .catch(err => console.log(`Could not run de html scripts ${err}`));
    }
  }

  render() {
    const { apiSessionSubmit, userid } = this.props;

    let userData = {};

    let isFidoUser;

    if (isLocalStorageSupported(true)) {
      userData = localStorage.getItem('userData') || '{}';

      userData = JSON.parse(userData);
      userData[userid] = getParameterCaseInsensitive(userData, userid) || {};
      userData[userid] = userData[userid] || {};
      userData[userid].fido2 = userData[userid].fido2 || {};
      isFidoUser = userData[userid].fido2.localCredentials;
    }
    const identifyValidUser = apiSessionSubmit || isFidoUser;
    const changePasswordRules = [identifyValidUser];
    const validatedUser = checkValidation(changePasswordRules);

    return (
      <>
        <Switch>
          <Route
            exact
            path={ HOME }
            component={ LoginContainer }
          />
          <Suspense fallback={ loading }>
            <ProtectedRoute
              exact
              path={ MOBILE_LIST_PAGE }
              component={ MobileListContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ FIDO_TEXTLINK_PAGE }
              component={ MobileListContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ FIDO_DEVICE_LIST }
              component={ MobileListContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ FIDO_DEVICE_REGISTER }
              component={ CommonDisplayPageContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ SUCCESS_PAGE }
              component={ CommonDisplayPageContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ FIDO_REGISTER_SUCCESS }
              component={ CommonDisplayPageContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ FIDO_SETUP_CANCEL_PAGE }
              component={ CommonDisplayPageContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ FIDO_SETUP_ERROR_PAGE }
              component={ CommonDisplayPageContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ FIDO_AUTHENTICATE_SUCCESS }
              component={ CommonDisplayPageContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ MESSAGE_SENT_PAGE }
              component={ CommonDisplayPageContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ STEP_UP_CHANNELS }
              component={ ChannelListContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ QUESTION_COLLECTION }
              component={ QuestionCollectionContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ CHANNEL_COLLECTION }
              component={ ChannelCollectionContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ MOBILE_COLLECTION }
              component={ MobileCollectionContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ LANDLINE_COLLECTION }
              component={ LandlineCollectionContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ EMAIL_COLLECTION }
              component={ EmailCollectionContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ VOICE_BIOMETRICS_COLLECTION }
              component={ VoiceBioMetricsCollectionContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ STEP_UP_VERIFICATION }
              component={ OTPContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ CHANGE_PASSWORD }
              component={ ChangePasswordContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ TERMS_AND_CONDITIONS }
              component={ TCContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ STEP_UP_QNA }
              component={ QNAContainer }
              loggedIn={ validatedUser }
            />
            <ProtectedRoute
              exact
              path={ ERROR_PAGE }
              component={ ErrorPageContainer }
              loggedIn={ validatedUser }
            />
          </Suspense>
          <Redirect
            from="*"
            to={ HOME }
          />
        </Switch>
      </>
    );
  }
}

export const ProtectedRoute = ({
  // eslint-disable-next-line no-shadow
  component: Comp,
  loggedIn,
  path,
  ...rest
}) => (
  <Route
    path={ path }
    { ...rest }
    render={ props => loggedIn ? <Comp { ...props } /> : <Redirect to={ `${HOME}/${getUrlLocationSearch()}` } />
    }
  />
);

const checkValidation = (rules) => {
  for (let i = 0; i < rules.length; i++) {
    if (!rules[i]) {
      return false;
    }
  }
  return true;
};

ProtectedRoute.propTypes = {
  path: PropTypes.string,
};

Routes.propTypes = {
  apiSessionSubmit: PropTypes.string,
  userid: PropTypes.string,
  redirectURL: PropTypes.func,
};

export default Routes;
