import React from 'react';
import { intlShape, FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  SdfButton,
  SdfSelectSimple,
  SdfPhoneNumberInput,
  SdfInput,
  SdfAlert,
} from '@waypoint/react-components';
import { constants } from './channel-collection-constants';

import {
  isLandlineCollection,
  isValidExtn,
  isNotBlank,
  isBlank,
  isEnterKeyPressed,
} from '../../util/misc';
import { ChannelCollectionError, PasscodePageLayout } from '.';

const {
  PERSONAL, BUSINESS, LANDLINE, NOT_CONSENT, DEFAULT_COUNTRY_CODE_NUMBER,
} = constants;

const LandlineCollectionForm = (props) => {
  const {
    currentlySending,
    saveCommunicationChannel,
    landlineBusinessValue,
    setLandlineBusinessValue,
    landlinePersonalValue,
    setLandlinePersonalValue,
    landlineUsageType,
    onSetLandlineUsageType,
    validationErrors,
    channelCollectionChallenge,
    skipChannelCollection,
    channelCollectionFailed,
    channelCollectionPassed,
    setExtnValue,
    extnValue,
    setAddLandlineFlag,
    addLandlineFlag,
    clearExtnValue,
    optOutChannelCollection,
    intl,
    businessLandlineValid,
    personalLandlineValid,
    businessLandlineErrMsg,
    personalLandlineErrMsg,
    setBusinessLandlineValid,
    setPersonalLandlineValid,
    setBusinessLandlineErrMsg,
    setPersonalLandlineErrMsg,
    businessCountryCode,
    setBusinessCountryCode,
    personalCountryCode,
    setPersonalCountryCode,
    isAppView,
  } = props;

  const invalidPhoneNumberErrMsg = intl.formatMessage({
    id: 'channelcollection_invalid_phone_number_label2',
    defaultMessage: 'Invalid phone number.',
  });

  const invalidCountryCodeErrMsg = intl.formatMessage({
    id: 'channelcollection_invalid_country_code_label',
    defaultMessage: 'Invalid country code.',
  });

  const allowedCountryCodeList = ['US', 'CA'];

  const items = [
    {
      label: intl.formatMessage({
        id: 'channelcollection_landline1',
        defaultMessage: 'Work landline',
      }),
      value: BUSINESS,
    },
    {
      label: intl.formatMessage({
        id: 'channelcollection_landline2',
        defaultMessage: 'Home landline',
      }),
      value: PERSONAL,
    },
  ];

  const handleBusinessLandlineChange = (event) => {
    const phoneObj = event.detail;

    const { valueInternational, countryPrefix, countryCode } = phoneObj;

    setLandlineBusinessValue(phoneObj.valueNational);
    if (isCountryCodeAllowed(countryPrefix) && valueInternational !== '') {
      setBusinessCountryCode(countryCode);
      setBusinessLandlineValid(true);
    } else if (valueInternational === '') {
      setBusinessLandlineError(invalidPhoneNumberErrMsg);
    } else {
      setBusinessLandlineError(invalidCountryCodeErrMsg);
    }
  };

  const handlePersonalLandlineChange = (event) => {
    const phoneObj = event.detail;

    const { valueInternational, countryPrefix, countryCode } = phoneObj;

    setLandlinePersonalValue(phoneObj.valueNational);
    if (isCountryCodeAllowed(countryPrefix) && valueInternational !== '') {
      setPersonalLandlineValid(true);
      setPersonalCountryCode(countryCode);
    } else if (valueInternational === '') {
      setPersonalLandlineError(invalidPhoneNumberErrMsg);
    } else {
      setPersonalLandlineError(invalidCountryCodeErrMsg);
    }
  };

  const handleBusinessInvalidLandlineChange = (event) => {
    const phoneObj = event.detail;

    const { valueInternational } = phoneObj;

    setLandlineBusinessValue(getLandlineNumberWithoutCountryCode(valueInternational));
    if (
      valueInternational === '' ||
      getCountryCode(valueInternational) === DEFAULT_COUNTRY_CODE_NUMBER
    ) {
      setBusinessLandlineError(invalidPhoneNumberErrMsg);
    } else {
      setBusinessLandlineError(invalidCountryCodeErrMsg);
    }
  };

  const handlePersonalInvalidLandlineChange = (event) => {
    const phoneObj = event.detail;

    const { valueInternational } = phoneObj;

    setLandlinePersonalValue(getLandlineNumberWithoutCountryCode(valueInternational));
    if (
      valueInternational === '' ||
      getCountryCode(valueInternational) === DEFAULT_COUNTRY_CODE_NUMBER
    ) {
      setPersonalLandlineError(invalidPhoneNumberErrMsg);
    } else {
      setPersonalLandlineError(invalidCountryCodeErrMsg);
    }
  };

  const handleBusinessCountryCodeChange = (event) => {
    const countryPrefix = event.detail;

    if (!isCountryCodeAllowed(countryPrefix)) {
      setBusinessLandlineError(invalidCountryCodeErrMsg);
    }
  };

  const handlePersonalCountryCodeChange = (event) => {
    const countryPrefix = event.detail;

    if (!isCountryCodeAllowed(countryPrefix)) {
      setPersonalLandlineError(invalidCountryCodeErrMsg);
    }
  };

  const { communicationCollectionType } = channelCollectionChallenge;

  const onSave = () => {
    const addedCommunications = [];
    const changedCommunications = [];

    if (
      !addLandlineFlag &&
      isLandlineCollection(communicationCollectionType) &&
      landlineUsageType === BUSINESS
    ) {
      addedCommunications.push({
        type: LANDLINE,
        countryCode: businessCountryCode,
        areaCode: getAreaCode(landlineBusinessValue),
        localNumber: getLocalNumber(landlineBusinessValue),
        usageType: landlineUsageType,
        extension: isNotBlank(extnValue) ? extnValue : undefined,
        consentStatus: NOT_CONSENT,
      });
    }
    if (
      !addLandlineFlag &&
      isLandlineCollection(communicationCollectionType) &&
      landlineUsageType === PERSONAL
    ) {
      addedCommunications.push({
        type: LANDLINE,
        countryCode: personalCountryCode,
        areaCode: getAreaCode(landlinePersonalValue),
        localNumber: getLocalNumber(landlinePersonalValue),
        usageType: landlineUsageType,
        consentStatus: NOT_CONSENT,
      });
    }
    if (addLandlineFlag && isLandlineCollection(communicationCollectionType)) {
      addedCommunications.push({
        type: LANDLINE,
        countryCode: businessCountryCode,
        areaCode: getAreaCode(landlineBusinessValue),
        localNumber: getLocalNumber(landlineBusinessValue),
        usageType: BUSINESS,
        extension: isNotBlank(extnValue) ? extnValue : undefined,
        consentStatus: NOT_CONSENT,
      });
      addedCommunications.push({
        type: LANDLINE,
        countryCode: personalCountryCode,
        areaCode: getAreaCode(landlinePersonalValue),
        localNumber: getLocalNumber(landlinePersonalValue),
        usageType: PERSONAL,
        consentStatus: NOT_CONSENT,
      });
    }

    saveCommunicationChannel(addedCommunications, changedCommunications);
  };

  const getLandlineNumberWithoutCountryCode = number => number && number.slice(2);

  const getCountryCode = number => number && number.slice(0, 2);

  const getAreaCode = landlineValue => landlineValue && landlineValue.slice(0, 3);

  const getLocalNumber = landlineValue => landlineValue && landlineValue.slice(3);

  const isCountryCodeAllowed = countryPrefix => allowedCountryCodeList.includes(countryPrefix);

  const isLandlineBlank = () => {
    if (!addLandlineFlag && landlineUsageType === BUSINESS) {
      return isBlank(landlineBusinessValue);
    }

    if (!addLandlineFlag && landlineUsageType === PERSONAL) {
      return isBlank(landlinePersonalValue);
    }
    return isBlank(landlineBusinessValue) || isBlank(landlinePersonalValue);
  };

  const isErrorPresent = () => {
    const invalidPhoneFormatErrMsg = intl.formatMessage({
      id: 'channelcollection_invalid_phone_number_label',
      defaultMessage: 'Invalid phone number format.',
    });

    if (validationErrors) {
      if (validationErrors.length === 2) {
        setBusinessLandlineError(invalidPhoneFormatErrMsg);
        setPersonalLandlineError(invalidPhoneFormatErrMsg);
      } else if (validationErrors.length === 1) {
        if (validationErrors.some(error => error.field === 'response.addedCommunications[0]')) {
          if (landlineUsageType === BUSINESS) {
            setBusinessLandlineError(invalidPhoneFormatErrMsg);
          } else {
            setPersonalLandlineError(invalidPhoneFormatErrMsg);
          }
        } else if (landlineUsageType === BUSINESS) {
          setPersonalLandlineError(invalidPhoneFormatErrMsg);
        } else {
          setBusinessLandlineError(invalidPhoneFormatErrMsg);
        }
      }
    }
  };

  const setPersonalLandlineError = (errorMessage) => {
    setPersonalLandlineValid(false);
    setPersonalLandlineErrMsg(errorMessage);
  };

  const setBusinessLandlineError = (errorMessage) => {
    setBusinessLandlineValid(false);
    setBusinessLandlineErrMsg(errorMessage);
  };

  const isSaveButtonDisabled =
    isLandlineBlank() ||
    !isValidExtn(extnValue) ||
    isErrorPresent() ||
    !businessLandlineValid ||
    !personalLandlineValid ||
    currentlySending;

  const handleSkip = () => {
    !currentlySending && optOutChannelCollection(true);
  };

  const handleSkipButtonClick = () => {
    handleSkip();
  };

  const handleSkipButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleSkip();
    }
  };

  const handleRemindMe = () => {
    !currentlySending && skipChannelCollection(true);
  };

  const handleRemindMeClick = () => {
    handleRemindMe();
  };

  const handleRemindMeButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleRemindMe();
    }
  };

  const handleSend = () => {
    !isSaveButtonDisabled && onSave();
  };

  const handleSendClick = () => {
    handleSend();
  };

  const handleSendButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleSend();
    }
  };

  const handleAddAdditionalLandline = () => {
    setAddLandlineFlag(true, landlineUsageType, landlineBusinessValue, landlinePersonalValue);
  };

  const handleAddAdditionalLandlineClick = () => {
    handleAddAdditionalLandline();
  };

  const handleAddAdditionalLandlineButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleAddAdditionalLandline();
    }
  };

  const handleBusinessLandlineDelete = () => {
    setAddLandlineFlag(false, landlineUsageType, landlineBusinessValue, landlinePersonalValue);
    landlineUsageType === BUSINESS && setPersonalLandlineValid(true);
  };

  const handleBusinessLandlineDeleteClick = () => {
    handleBusinessLandlineDelete();
  };

  const handleBusinessLandlineDeleteButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleBusinessLandlineDelete();
    }
  };

  const handlePersonalLandlineDelete = () => {
    setAddLandlineFlag(false, landlineUsageType, landlineBusinessValue, landlinePersonalValue);
    landlineUsageType === PERSONAL && clearExtnValue();
    landlineUsageType === PERSONAL && setBusinessLandlineValid(true);
  };

  const handlePersonalLandlineDeleteClick = () => {
    handlePersonalLandlineDelete();
  };

  const handlePersonalLandlineDeleteButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handlePersonalLandlineDelete();
    }
  };

  return (
    (channelCollectionFailed && <ChannelCollectionError { ...props } />) ||
    (channelCollectionPassed && <PasscodePageLayout { ...props } />) || (
      <div
        id="channel-collection-landline"
        className="flex flex-col"
      >
        <div className="font-normal text-md leading-md mt-2 md:mt-4 mb-4">
          <FormattedMessage
            id="channelcollection_landline_primary"
            // eslint-disable-next-line max-len
            defaultMessage="To ensure your security during support calls, add a landline phone number to your account. If you don't have a landline phone number, you can skip this step."
          />
        </div>

        <div className="flex flex-row justify-center">
          <div
            className={ isAppView ? 'w-full mb-6' : 'max-w-xs w-full md:max-w-full md:mx-20 mb-6' }
          >
            <SdfSelectSimple
              id="channel-usagetype-landline-id"
              state="normal"
              className={ isAppView ? 'w-full' : 'max-w-xs w-full' }
              label={ intl.formatMessage({
                id: 'channelcollection_landline_dropdown_label',
                defaultMessage: 'Number Type',
              }) }
              items={ items }
              value={ items.filter(item => item.value === landlineUsageType)[0] }
              onSdfChange={ (event) => {
                onSetLandlineUsageType(event.detail.value);
              } }
            />
          </div>
        </div>

        { landlineUsageType === BUSINESS && (
          <div className="flex flex-col justify-center">
            <div
              className={
                isAppView ?
                  'flex flex-col w-full mb-6 gap-6' :
                  'flex md:flex-row flex-col items-center md:items-start md:mx-20 mb-6 gap-6'
              }
            >
              <SdfPhoneNumberInput
                id="work_landline_number"
                label={ intl.formatMessage({
                  id: 'channelcollection_landline1_label',
                  defaultMessage: 'Work Landline Phone Number',
                }) }
                filterable="false"
                state={ !businessLandlineValid || isErrorPresent() ? 'error' : 'normal' }
                requiredState="required"
                className={ isAppView ? 'w-full' : 'max-w-xs w-full' }
                placeholder=""
                onSdfCountryCodeChange={ handleBusinessCountryCodeChange }
                onSdfChange={ handleBusinessLandlineChange }
                onSdfInvalidNumber={ handleBusinessInvalidLandlineChange }
                onKeyPress={ handleSendButtonKeyPress }
              >
                { (!businessLandlineValid || isErrorPresent()) && businessLandlineErrMsg !== '' && (
                  <SdfAlert
                    type="inline"
                    status="error"
                    slot="alert"
                    content="after"
                  >
                    { businessLandlineErrMsg }
                  </SdfAlert>
                ) }
              </SdfPhoneNumberInput>

              <SdfInput
                id="extn-id"
                label={ intl.formatMessage({
                  id: 'channelcollection_landline_extn',
                  defaultMessage: 'Extension',
                }) }
                className={ isAppView ? 'w-full' : 'max-w-xs w-full md:max-w-1/4' }
                disabled={ currentlySending || undefined }
                autoComplete="off"
                maxLength={ 8 }
                value={ extnValue }
                onSdfInput={ (event) => {
                  setExtnValue(event.detail);
                } }
                type="text"
                onKeyPress={ handleSendButtonKeyPress }
              />
            </div>
          </div>
        ) }

        { landlineUsageType === PERSONAL && (
          <div className="flex flex-col justify-center">
            <div
              className={
                isAppView ? 'w-full mb-6' : 'flex md:flex-row flex-col items-center md:mx-20 mb-6'
              }
            >
              <SdfPhoneNumberInput
                id="home_landline_number"
                label={ intl.formatMessage({
                  id: 'channelcollection_landline1_labe2',
                  defaultMessage: 'Home Landline Phone Number',
                }) }
                filterable="false"
                state={ !personalLandlineValid || isErrorPresent() ? 'error' : 'normal' }
                requiredState="required"
                className={ isAppView ? 'w-full' : 'max-w-xs w-full' }
                placeholder=""
                onSdfCountryCodeChange={ handlePersonalCountryCodeChange }
                onSdfChange={ handlePersonalLandlineChange }
                onSdfInvalidNumber={ handlePersonalInvalidLandlineChange }
                onKeyPress={ handleSendButtonKeyPress }
              >
                { (!personalLandlineValid || isErrorPresent()) && personalLandlineErrMsg !== '' && (
                  <SdfAlert
                    type="inline"
                    status="error"
                    slot="alert"
                    content="after"
                  >
                    { personalLandlineErrMsg }
                  </SdfAlert>
                ) }
              </SdfPhoneNumberInput>
            </div>
          </div>
        ) }
        <div>
          { !addLandlineFlag && (
            <div className="flex flex-col justify-center">
              <div
                className={
                  isAppView ?
                    'flex flex-col items-center w-full mb-6 ' :
                    'flex md:flex-row flex-col items-center md:mx-20 mb-6 md:mb-8'
                }
              >
                <SdfButton
                  id="add-additional-landline"
                  icon="plus"
                  emphasis="tertiary"
                  size="lg"
                  disabled={ currentlySending || undefined }
                  variant="text"
                  onClick={ handleAddAdditionalLandlineClick }
                  onKeyPress={ handleAddAdditionalLandlineButtonKeyPress }
                >
                  <FormattedMessage
                    id="channelcollection_add_landline_label"
                    defaultMessage="Add an additional landline"
                  />
                </SdfButton>
              </div>
            </div>
          ) }
        </div>

        { addLandlineFlag && (
          <div>
            <hr className="border-t-2 border-solid border-dark mb-6" />
            { landlineUsageType === PERSONAL && (
              <div className="flex flex-col justify-center">
                <div
                  className={
                    isAppView ?
                      'flex flex-col w-full mb-6 gap-6' :
                      'flex md:flex-row flex-col items-center md:items-start md:mx-20 mb-6 gap'
                  }
                >
                  <SdfPhoneNumberInput
                    id="work_landline_number"
                    label={ intl.formatMessage({
                      id: 'channelcollection_landline1_label',
                      defaultMessage: 'Work Landline Phone Number',
                    }) }
                    filterable="false"
                    state={ !businessLandlineValid || isErrorPresent() ? 'error' : 'normal' }
                    requiredState="required"
                    className={ isAppView ? 'w-full' : 'max-w-xs md:min-w-xs w-full' }
                    placeholder=""
                    onSdfCountryCodeChange={ handleBusinessCountryCodeChange }
                    onSdfChange={ handleBusinessLandlineChange }
                    onSdfInvalidNumber={ handleBusinessInvalidLandlineChange }
                    onKeyPress={ handleSendButtonKeyPress }
                  >
                    { (!businessLandlineValid || isErrorPresent()) && businessLandlineErrMsg !== '' && (
                      <SdfAlert
                        type="inline"
                        status="error"
                        slot="alert"
                        content="after"
                      >
                        { businessLandlineErrMsg }
                      </SdfAlert>
                    ) }
                  </SdfPhoneNumberInput>
                  <div
                    className={
                      isAppView ?
                        'flex flex-row w-full' :
                        'flex flex-row max-w-xs w-full md:max-w-full md:gap'
                    }
                  >
                    <SdfInput
                      id="extn-id"
                      label={ intl.formatMessage({
                        id: 'channelcollection_landline_extn',
                        defaultMessage: 'Extension',
                      }) }
                      className={ isAppView ? 'min-w-10/12 w-full' : 'min-w-0 w-full md:max-w-2/5' }
                      disabled={ currentlySending || undefined }
                      autoComplete="off"
                      maxLength={ 8 }
                      value={ extnValue }
                      onSdfInput={ (event) => {
                        setExtnValue(event.detail);
                      } }
                      type="text"
                      onKeyPress={ handleSendButtonKeyPress }
                    />
                    <div className="flex flex-col justify-center mt-4">
                      <SdfButton
                        id="delete-additional-landline"
                        button-title="Remove"
                        icon="action-delete"
                        icon-only
                        emphasis="tertiary"
                        size="lg"
                        onClick={ handlePersonalLandlineDeleteClick }
                        onKeyPress={ handlePersonalLandlineDeleteButtonKeyPress }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) }
            { landlineUsageType === BUSINESS && (
              <div className="flex flex-row justify-center">
                <div
                  className={
                    isAppView ? 'w-full mb-6' : 'max-w-xs w-full md:max-w-full md:mx-20 mb-6 md:gap'
                  }
                >
                  <div className="flex flex-row md:gap">
                    <SdfPhoneNumberInput
                      id="home_landline_number"
                      label={ intl.formatMessage({
                        id: 'channelcollection_landline1_labe2',
                        defaultMessage: 'Home Landline Phone Number',
                      }) }
                      filterable="false"
                      state={ !personalLandlineValid || isErrorPresent() ? 'error' : 'normal' }
                      requiredState="required"
                      className={ isAppView ? 'min-w-10/12 w-full' : 'min-w-0 md:w-xs' }
                      placeholder=""
                      onSdfCountryCodeChange={ handlePersonalCountryCodeChange }
                      onSdfChange={ handlePersonalLandlineChange }
                      onSdfInvalidNumber={ handlePersonalInvalidLandlineChange }
                      onKeyPress={ handleSendButtonKeyPress }
                    >
                      { (!personalLandlineValid || isErrorPresent()) &&
                        personalLandlineErrMsg !== '' && (
                          <SdfAlert
                            type="inline"
                            status="error"
                            slot="alert"
                            content="after"
                          >
                            { personalLandlineErrMsg }
                          </SdfAlert>
                      ) }
                    </SdfPhoneNumberInput>
                    <div className="flex flex-col justify-center mt-4">
                      <SdfButton
                        id="delete-additional-landline"
                        button-title="Remove"
                        icon="action-delete"
                        icon-only
                        emphasis="tertiary"
                        size="lg"
                        onClick={ handleBusinessLandlineDeleteClick }
                        onKeyPress={ handleBusinessLandlineDeleteButtonKeyPress }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) }
          </div>
        ) }

        <hr className="border-t-2 border-solid border-dark mb-4" />

        <div className="font-normal text-md leading-md mb-4 md:mb-10">
          <FormattedMessage
            id="channelcollection_landline_subline"
            // eslint-disable-next-line max-len
            defaultMessage="We'll send a text message to your primary mobile number for verification."
          />
        </div>

        <div className="flex md:flex-row flex-col justify-center md:justify-between gap">
          <div
            className={
              isAppView ?
                'w-full order-2 md:w-2/4' :
                'flex md:flex-row flex-col items-center md:order-1 order-2'
            }
          >
            <SdfButton
              id="optout"
              emphasis="tertiary"
              className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
              disabled={ currentlySending || undefined }
              onClick={ handleSkipButtonClick }
              onKeyPress={ handleSkipButtonKeyPress }
            >
              <FormattedMessage
                id="channelcollection_landline_optout2"
                defaultMessage="Skip this step"
              />
            </SdfButton>
          </div>
          <div
            className={
              isAppView ?
                'flex md:flex-row flex-col w-full gap order-1' :
                'flex md:flex-row flex-col items-center md:justify-end gap md:order-2 order-1'
            }
          >
            <SdfButton
              id="send-text-message"
              emphasis="primary"
              className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
              disabled={ isSaveButtonDisabled || undefined }
              onClick={ handleSendClick }
              onKeyPress={ handleSendButtonKeyPress }
            >
              <FormattedMessage
                id="channelCollection_send_text_message"
                defaultMessage="send text message"
              />
            </SdfButton>
            <SdfButton
              id="channel-collection-remind"
              emphasis="secondary"
              variant="contained"
              className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
              disabled={ currentlySending || undefined }
              onClick={ handleRemindMeClick }
              onKeyPress={ handleRemindMeButtonKeyPress }
            >
              <FormattedMessage
                id="channelcollection_remindme_button_label"
                defaultMessage="remind me later"
              />
            </SdfButton>
          </div>
        </div>
      </div>
    )
  );
};

LandlineCollectionForm.propTypes = {
  currentlySending: PropTypes.bool,
  channelCollectionChallenge: PropTypes.shape({
    communicationCollectionType: PropTypes.string,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  saveCommunicationChannel: PropTypes.func,
  landlineBusinessValue: PropTypes.string,
  setLandlineBusinessValue: PropTypes.func,
  landlinePersonalValue: PropTypes.string,
  setLandlinePersonalValue: PropTypes.func,
  landlineUsageType: PropTypes.string,
  onSetLandlineUsageType: PropTypes.func,
  validationErrors: PropTypes.instanceOf(Array),
  skipChannelCollection: PropTypes.func,
  channelCollectionFailed: PropTypes.bool,
  channelCollectionPassed: PropTypes.bool,
  setExtnValue: PropTypes.func,
  extnValue: PropTypes.string,
  setAddLandlineFlag: PropTypes.func,
  addLandlineFlag: PropTypes.bool,
  clearExtnValue: PropTypes.func,
  optOutChannelCollection: PropTypes.func,
  businessLandlineValid: PropTypes.bool,
  personalLandlineValid: PropTypes.bool,
  businessLandlineErrMsg: PropTypes.string,
  personalLandlineErrMsg: PropTypes.string,
  setBusinessLandlineValid: PropTypes.func,
  setPersonalLandlineValid: PropTypes.func,
  setBusinessLandlineErrMsg: PropTypes.func,
  setPersonalLandlineErrMsg: PropTypes.func,
  businessCountryCode: PropTypes.string,
  setBusinessCountryCode: PropTypes.func,
  personalCountryCode: PropTypes.string,
  setPersonalCountryCode: PropTypes.func,
  isAppView: PropTypes.bool,
};

LandlineCollectionForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LandlineCollectionForm);
