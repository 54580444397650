import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from './cookie-helper';
import {
  ALIAS,
  ORGANIZATION_ID,
  PRODUCT_ID,
  RELATIVE_PATH_PREFIX,
  CALLING_APP_ID,
  ADP_BRANDING_COOKIE,
  EMAIL_REGEX,
  PASSCODE_REGEX,
  EXTN_REGEX,
  ZERO_CHECK_REGEX,
  ADP_PILOT_CONFIG_COOKIE,
  FIDO2ENABLED,
  USER_ACCOUNT_IDENTITY,
  USER_ACCOUNT_DATA,
  DEFAULT_TARGETURL,
  URL_REGEX,
  FED_TARGET_URL,
  VIEW,
  APP_VIEW,
  TRANSACTION_ID,
  STATE_PARAMETER,
} from './constants';
import { constants as loginConstants } from '../../components/login/login-comp-constants';
import { constants as channelCollectionConstants } from '../../components/channel-collection/channel-collection-constants';
import { getParameterByName } from './target-finder';
import { ADP_LANG_LOCALE_COOKIE } from '../locale';
import { getLocaleImage } from '../locale/locale-helper';
import { Controller } from '../../components/fido/Controller';
import { UserData, getSession } from '../../components/fido/common/session';
import { PendoUtil } from '../pendo';
import { FIDO_AUTHENTICATION } from '../pendo/constants';
import { getTargetUrl } from '.';
import { getMobilePlatform } from '../../components/fido/common/common';
import adpMobileLogo from '../../../images/logo-adp-fy19.svg';

let controller = new Controller();

const {
  FORGOT_PWD_URLNETSECURE,
  LANGUAGECOOKIE,
  COOKIE_GREET_FNAME,
  COOKIE_REMEMBER_ME,
  PMDATA,
} = loginConstants;

const {
  EMAIL,
  MOBILE_COLLECTION,
  EMAIL_COLLECTION,
  LANDLINE_COLLECTION,
  VOICE_BIOMETRICS_COLLECTION,
  COLLECT,
  VERIFY,
  PERIODIC_PROMPT,
  PERSONAL,
  BUSINESS,
} = channelCollectionConstants;

export function getParameterCaseInsensitive(object, key) {
  if (!key) {
    return {};
  }

  const asLowercase = key.toLowerCase();

  return object[Object.keys(object).filter(k => k.toLowerCase() === asLowercase)[0]];
}

const userData = new UserData();

export const isNotBlank = value => value !== undefined && value !== null && value.trim() !== '';
export const isNotEmpty = value => value !== undefined && value !== null && value.length > 0;
export const isBlank = value => !isNotBlank(value);

export const getAjaxJsonHeader = () => ({
  'Content-Type': 'application/json',
  'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
  'Accept-Language': getCookie(ADP_LANG_LOCALE_COOKIE) ?
    getCookie(ADP_LANG_LOCALE_COOKIE).replace(/[_]/, '-') :
    'en-US',
  ...getDeviceToken(),
  ...getPilotCookie(),
});

export const getOrganizationId = () => getParameterByName(ALIAS) ||
  getCookie(ADP_BRANDING_COOKIE) ||
  getParameterByName(ORGANIZATION_ID) ||
  '';

export const getOrganizationIdForBranding = () => {
  const brandingCookie = getCookie(ADP_BRANDING_COOKIE) ?
    `cookie:${getCookie(ADP_BRANDING_COOKIE)}` :
    '';

  return getParameterByName(ALIAS) || brandingCookie || getParameterByName(ORGANIZATION_ID) || '';
};

export const getProductId = () => getParameterByName(PRODUCT_ID) || '';

export const getCallingAppId = () => getParameterByName(CALLING_APP_ID) || '';

export const getFilteredUrl = url => (url.startsWith(RELATIVE_PATH_PREFIX) ? '' : url);

export const getTransactionId = () => getParameterByName(TRANSACTION_ID) || '';

export const getCookieCacheToken = () => getParameterByName(STATE_PARAMETER) || '';

// checks if view is App
export const isApp = () => {
  const view = getParameterByName(VIEW) || '';

  return view.toLowerCase() === APP_VIEW.toLowerCase();
};

export const passwordRecoveryUrlCheck = passwordRecoveryUrl => passwordRecoveryUrl === '' || passwordRecoveryUrl === undefined ?
  window.env && window.env.FORGOT_PWD_URLNETSECURE :
  FORGOT_PWD_URLNETSECURE;

export const isValidEmail = (email) => {
  const emailRegex = new RegExp(EMAIL_REGEX);

  return isNotBlank(email) && emailRegex.test(email);
};

// eslint-disable-next-line max-len
export const isOnlyBusinessEmailPresent = communications => communications.some(item => item.type === EMAIL && item.usageType === BUSINESS) &&
  !communications.some(item => item.type === EMAIL && item.usageType === PERSONAL);
// eslint-disable-next-line max-len
export const isOnlyPersonalEmailPresent = communications => communications.some(item => item.type === EMAIL && item.usageType === PERSONAL) &&
  !communications.some(item => item.type === EMAIL && item.usageType === BUSINESS);

// eslint-disable-next-line max-len
export const isPersonalEmailNotPresent = communications => !communications.some(item => item.type === EMAIL && item.usageType === PERSONAL);

// eslint-disable-next-line max-len
export const isPersonalandBusinessEmailPresent = communications => communications.some(item => item.type === EMAIL && item.usageType === PERSONAL) &&
  communications.some(item => item.type === EMAIL && item.usageType === BUSINESS);

// eslint-disable-next-line max-len
export const isMobileCollection = collectionType => collectionType.find(
  type => type.promptName === MOBILE_COLLECTION && type.promptStatus === COLLECT,
);

// eslint-disable-next-line max-len
export const isMobileVerification = collectionType => collectionType.find(
  type => type.promptName === MOBILE_COLLECTION && type.promptStatus === VERIFY,
);

// eslint-disable-next-line max-len
export const isPeriodicPrompt = collectionType => collectionType.find(
  type => type.promptName === MOBILE_COLLECTION && type.promptStatus === PERIODIC_PROMPT,
);

// eslint-disable-next-line max-len
export const isVoiceBioMetricsCollection = collectionType => collectionType &&
  collectionType.find(
    type => type.promptName === VOICE_BIOMETRICS_COLLECTION && type.promptStatus === COLLECT,
  );

// eslint-disable-next-line max-len
export const isEmailCollection = collectionType => collectionType.find(
  type => type.promptName === EMAIL_COLLECTION && type.promptStatus === COLLECT,
);

// eslint-disable-next-line max-len
export const isEmailVerification = collectionType => collectionType.find(type => type.promptName === EMAIL_COLLECTION && type.promptStatus === VERIFY);

// eslint-disable-next-line max-len
export const isMobileAndEmailActivation = collectionType => isMobileVerification(collectionType) && isEmailVerification(collectionType);

// eslint-disable-next-line max-len
export const isLandlineCollection = collectionType => collectionType.find(
  type => type.promptName === LANDLINE_COLLECTION && type.promptStatus === COLLECT,
);

export const isValidPasscode = (passcode) => {
  const mobileRegex = new RegExp(PASSCODE_REGEX);

  return isNotBlank(passcode) && mobileRegex.test(passcode);
};

export const isValidExtn = (extn) => {
  const extnRegex = new RegExp(EXTN_REGEX);
  const zeroCheckRegex = new RegExp(ZERO_CHECK_REGEX);

  return isNotBlank(extn) ? extnRegex.test(extn) && !zeroCheckRegex.test(extn) : true;
};

export const isEnterKeyPressed = key => key === 'Enter';

export const getDeviceToken = () => {
  const cookieData = getCookie('PMData');

  if (cookieData) {
    return {
      'ADP-Device-Token': cookieData,
    };
  }
  return {
    'ADP-Device-Token': '',
  };
};

export const getPilotCookie = () => {
  const cookieData = getCookie(ADP_PILOT_CONFIG_COOKIE);

  if (cookieData) {
    return {
      'olp-pilot-cookie': cookieData,
    };
  }
  return {
    'olp-pilot-cookie': '',
  };
};

export const setDeviceTokenForRequiredTypes = (response) => {
  const deviceToken = response.xhr && response.xhr.getResponseHeader('ADP-Device-Token');

  if (deviceToken) {
    setCookie('PMData', deviceToken);
  }
};

// eslint-disable-next-line max-len
export const getParamFromResponseHeader = (response, param) => response.xhr && response.xhr.getResponseHeader(param);
export const isLocalStorageSupported = (logRequired) => {
  try {
    if (!localStorage) {
      return false;
    }
  } catch (exception) {
    return false;
  }
  return true;
};

export const mapDynamicMessageImportanceToAlertType = (importance) => {
  switch (importance.toUpperCase()) {
    case 'HIGH':
      return { type: 'error', displayTitle: 'Critical' };
    case 'MEDIUM':
      return { type: 'warning', displayTitle: 'Alert' };
    case 'LOW':
      return { type: 'info', displayTitle: 'Info' };
    default:
      return { type: 'info', displayTitle: 'Info' };
  }
};

export const sleep = millisec => new Promise(resolve => setTimeout(resolve, Number(millisec)));

export const getDevicePrint = () => {
  let devicePrint = '';

  try {
    // eslint-disable-next-line camelcase
    if (typeof encode_deviceprint === 'function') {
      // eslint-disable-next-line no-undef
      devicePrint = encode_deviceprint();
    }
  } catch (error) {
    console.warn('loading deviceprint failed', error); // eslint-disable-line
  }

  return devicePrint;
};

export const handlePendoTracking = () => {
  try {
    const transactionid = uuidv4();

    if (
      window.env &&
      (window.env.PENDO_ENABLED === 'true' || window.env.PENDO_ENABLED === true) &&
      window.env.PENDO_API_KEY
    ) {
      (async () => {
        PendoUtil.pendoInitialize(window.env.PENDO_API_KEY, transactionid);
      })();
    }
  } catch {
    console.log('User not Tracked'); // eslint-disable-line
  }
};

export const extractBrandingImageProperty = (data, locale, filterRequired = true) => {
  let urlProp = '';

  let urlLocaleJson = '';

  if (data) {
    urlLocaleJson = data.i18n || '';
    urlProp = urlLocaleJson === '' ? data.default : getLocaleImage(urlLocaleJson, locale);
    if (filterRequired && urlProp) {
      return getFilteredUrl(urlProp);
    }
    return urlProp;
  }
  return '';
};

// eslint-disable-next-line max-len
export const isFidoUserinLocalStorage = userId => isLocalStorageSupported(true) && isNotEmpty(getPasskeyUserHandle(userId));

export const isFidoinLocalStorage =
  isLocalStorageSupported(true) && localStorage.getItem(FIDO2ENABLED) === 'true';

export const getFidoTokenForUser = (userId) => {
  const currentUser = userData.getData(userId);

  return currentUser && currentUser.fido2 && currentUser.fido2.fidoAuthenticatorToken;
};

export const getPasskeyUserHandle = (userId) => {
  const currentUser = userData.getData(userId);

  return currentUser && currentUser.fido2 && currentUser.fido2.localCredentials[0];
};

export const removeFidoLocalStorage = () => {
  isLocalStorageSupported(true) && localStorage.removeItem(FIDO2ENABLED);
};

export const findAndReplaceText = (data, currentText, newText) => {
  if (data) {
    const localBrandDatawithDynamicYear = JSON.stringify(data).replace(currentText, newText);

    return JSON.parse(localBrandDatawithDynamicYear);
  }
  return '';
};

export const getController = () => controller;

export const setController = (controllerLocal) => {
  controller = controllerLocal;
};
export const startCoBrowseSession = (event) => {
  if (event) {
    event.preventDefault();
  }
  // eslint-disable-next-line no-undef
  GLANCE.Cobrowse.Visitor.startSession();
};

export function getadpAccountId(userId) {
  let userAccountIdData = {};

  let userAccountData = {};

  let adpAccountId = '';

  if (isLocalStorageSupported(true)) {
    userAccountData = localStorage.getItem(USER_ACCOUNT_DATA) || '{}';

    userAccountData = JSON.parse(userAccountData);
    adpAccountId = userAccountData[userId] || '';

    if (adpAccountId === '') {
      userAccountIdData = localStorage.getItem(USER_ACCOUNT_IDENTITY) || '{}';

      userAccountIdData = JSON.parse(userAccountIdData);
      adpAccountId = userAccountIdData[userId] || '';
      userAccountData[userId] = adpAccountId;
      userAccountData = JSON.stringify(userAccountData);
      localStorage.setItem(USER_ACCOUNT_DATA, userAccountData);
    }
  }
  return adpAccountId;
}

export function pendoFidoTracking(userId) {
  try {
    const adpAccountId = getadpAccountId(userId.toLowerCase());

    (async () => {
      PendoUtil.pendoUpdate(adpAccountId);
    })();
  } catch {
    console.log('User is not tracked'); // eslint-disable-line
  }
}

export function anonymousPendoUser() {
  (async () => {
    try {
      PendoUtil.clearPendoSession();
      PendoUtil.pendoUpdate('');
    } catch {
      console.log('user is not tracked'); // eslint-disable-line
    }
  })();
}

export function pendoOnClick(userId, locale, version) {
  try {
    pendoFidoTracking(userId);
    (async () => {
      PendoUtil.pendoTrackEvent(FIDO_AUTHENTICATION, locale, version);
    })();
  } catch {
    console.log('User is not tracked'); // eslint-disable-line
  }
}

export function trackEvents(eventName) {
  try {
    (async () => {
      PendoUtil.pendoTrackEvent(eventName);
    })();
  } catch {
    console.log('User is not tracked'); // eslint-disable-line
  }
}

export const checkNnlToggle = () => {
  if (
    window.env &&
    (window.env.DISABLE_NNL_FIDO === 'true' || window.env.DISABLE_NNL_FIDO === true)
  ) {
    return false;
  }
  return true;
};

export const displayFidoRegMac = () => {
  if (
    (navigator.userAgent.indexOf('Mac') !== -1 || getMobilePlatform() === 'ios') &&
    window.env &&
    (window.env.DISABLE_MACOS_DEVICE_FIDO_WEBAUTHN === 'true' ||
      window.env.DISABLE_MACOS_DEVICE_FIDO_WEBAUTHN === true)
  ) {
    return false;
  }
  return true;
};

export function getRegionContext() {
  if (window.env && window.env.REGION_CONTEXT_PARAMETER) {
    return window.env.REGION_CONTEXT_PARAMETER;
  }
  return document.location.pathname;
}

export function preConnectTarget() {
  const entry = getTargetUrl();

  if (entry !== DEFAULT_TARGETURL) {
    const targetVal = entry.match(URL_REGEX);

    const origin = targetVal[1];
    const fragment = document.createDocumentFragment();
    const link = document.createElement('link');

    link.rel = 'preconnect';
    link.href = origin;
    link.crossOrigin = origin.crossOrigin;
    fragment.appendChild(link);
    document.head.appendChild(fragment);
  }
}

export const buildFedTargetUrl = () => {
  let link = window.location.href;

  link = link.replace(getRegionContext(), FED_TARGET_URL);

  link = encodeURIComponent(link);

  return link;
};

export const adpDefaultLogo = () => (
  <img
    id="mobileLogo"
    src={ adpMobileLogo }
    height="60"
    alt="ADP Home"
    className="block md:hidden"
  />
);

export const ImageLinkLogo = productLogoImage => (
  <>
    <img
      id="productLogo"
      src={ productLogoImage }
      alt="ADP Home"
      style={ { maxWidth: '544px', maxHeight: '60px' } }
      className="hidden md:block"
    />
    { adpDefaultLogo() }
  </>
);

export const setFocusOnInput = (inputEl) => {
  try {
    if (inputEl && typeof inputEl.setFocus === 'function') {
      inputEl.setFocus();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('unable to autofocus on username', error);
  }
};

export function getCookieCacheInfo(rememberUserIdChecked, userid) {
  let adpUserId;

  if (rememberUserIdChecked) {
    adpUserId = userid;
  }
  const adpUserFirstName = getCookie(COOKIE_GREET_FNAME);
  const pmData = getCookie(PMDATA);
  const adpLangLocaleCookie = getCookie(LANGUAGECOOKIE);

  const cookieValue = {};

  cookieValue.adpUserId = adpUserId;
  if (adpUserFirstName) cookieValue.adpUserFirstName = adpUserFirstName;
  if (pmData) cookieValue.pmData = pmData;
  if (adpLangLocaleCookie) cookieValue.adpLangLocaleCookie = adpLangLocaleCookie;

  return Object.keys(cookieValue).length >= 1 ? cookieValue : undefined;
}

export const setCookieCacheInfo = (cookieCacheInfo) => {
  if (cookieCacheInfo.adpUserId) setCookie(COOKIE_REMEMBER_ME, cookieCacheInfo.adpUserId);
  if (cookieCacheInfo.adpUserFirstName) {
    setCookie(COOKIE_GREET_FNAME, cookieCacheInfo.adpUserFirstName);
  }
  if (cookieCacheInfo.pmData) setCookie(PMDATA, cookieCacheInfo.pmData);
  if (cookieCacheInfo.adpLangLocaleCookie) {
    setCookie(LANGUAGECOOKIE, cookieCacheInfo.adpLangLocaleCookie);
  }
};

// eslint-disable-next-line max-len
export const checkFidoAuthRequired = (userid, switchToPassword) => checkNnlToggle() && !switchToPassword && isFidoUserinLocalStorage(userid);

export const isDarkMode = () => {
  const darkModeEnabled =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  return darkModeEnabled;
};
