import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  SdfBox, SdfBoxStack, SdfFlagLayout, SdfIcon,
} from '@waypoint/react-components';
import { isEnterKeyPressed } from '../../util/misc';

const ChannelListForm = (props) => {
  const {
    sendOTP,
    channelsDisplaySession,
    channelsDisplayChallenge,
    currentlySending,
    isAppView,
  } = props;
  const { authenticators } = channelsDisplayChallenge;

  const mobiles = [];
  const emails = [];
  const pushOption = [];
  const typeResponse = 'AUTHENTICATOR_PICKER_RESPONSE';

  authenticators.map((item) => {
    if (item.type === 'PUSH') {
      pushOption.push(item);
    } else if (item.type === 'EMAIL') {
      emails.push(item);
    } else if (item.type === 'MOBILE') {
      mobiles.push(item);
    }

    return item;
  });

  const channels = [
    {
      type: 'PUSH',
      data: pushOption,
      listButton: {
        iconName: 'brand-adp',
        id: 'pushListButton',
        iconClassName: 'text-4xl text-brand-secondary shadow-2 rounded-md box-border',
      },
      button: {
        id: 'email-button',
        style: 'link',
      },
      title: {
        id: 'stepup.push.title',
        defaultMessage: 'Send me push notification.',
        description: 'stepup email text',
      },
      subtitle: {
        id: 'stepup.push.subtitle',
        defaultMessage: 'Requires ADP mobile app',
        description: 'Requires ADP mobile app',
      },
      communicator: 'address',
    },
    {
      type: 'Mobile',
      data: mobiles,
      listButton: {
        iconName: 'ext-mdi-smartphone',
        id: 'mobileListButton',
        iconClassName: 'text-3xl text-link scale-75 sm:transform',
      },
      button: {
        id: 'mobile-button',
        style: 'link',
      },
      title: {
        id: 'stepup.mobile.text',
        defaultMessage: 'Send me a text.',
        description: 'stepup mobile text',
      },
      communicator: 'formattedNumber',
    },
    {
      type: 'Email',
      data: emails,
      listButton: {
        iconName: 'action-email',
        id: 'emailListButton',
        iconClassName: 'text-3xl text-link scale-75 sm:transform',
      },
      button: {
        id: 'email-button',
        style: 'link',
      },
      title: {
        id: 'stepup.email.text',
        defaultMessage: 'Send me an email.',
        description: 'stepup email text',
      },
      communicator: 'address',
    },
  ];
  const noChannelFound =
    (emails === undefined || emails.length === 0) &&
    (mobiles === undefined || mobiles.length === 0);

  const onSelectChannel = () => {
    if (mobiles.length > 0) {
      return mobiles[0].formattedNumber;
    }
    if (emails.length > 0) {
      return emails[0].address;
    }
    return '';
  };

  useState(onSelectChannel());

  // eslint-disable-next-line max-len
  const onSendOTP = (authenticatorId, authenticatorType, authenticatorFormat) => !currentlySending &&
    sendOTP(
      typeResponse,
      authenticatorId,
      authenticatorType,
      authenticatorFormat,
      channelsDisplaySession,
    );

  return (
    <div
      id="frmLogin"
      className="flex flex-col"
    >
      <div>
        { noChannelFound ? (
          <div className="text-right">
            { ' ' }
            <FormattedMessage
              id="stepup.nochannel.found.text"
              defaultMessage="No Channel Found"
              description="stepup nochannel found text"
            />
            { ' ' }
          </div>
        ) : (
          <div>
            <div className="md:font-medium md:not-italic text-md md:leading-md mt-2">
              { ' ' }
              <FormattedMessage
                id="stepup.verify.subtext"
                defaultMessage="To protect your information, select an option to verify your identity"
                description="stepup Subtext"
              />
            </div>
            <div className={ isAppView ? 'flex-row flex-1 pt-8' : 'mx-auto my-0 pt-4 mb-4 mt-2' }>
              <SdfBoxStack>
                { channels.map(channel => channel.data.map(item => (
                  <div>
                    { item.type === 'PUSH' && (
                    <SdfBox
                      id={ channel.listButton.id }
                      data-testid={ channel.listButton.id }
                      style={ { cursor: 'pointer' } }
                      variant="shadowed"
                      tabIndex={ 0 }
                      role="button"
                      class="supports-focus-visible rounded-xl hydrated box-bordered hover:bg-action-primary-50 focus:bg-action-primary-50"
                      onClick={ () => {
                        onSendOTP(item.authenticatorId, item.type, item[channel.communicator]);
                      } }
                      onKeyDown={ (event) => {
                        isEnterKeyPressed(event.key) &&
                              onSendOTP(
                                item.authenticatorId,
                                item.type,
                                item[channel.communicator],
                              );
                      } }
                    >
                      <SdfFlagLayout>
                        <SdfIcon
                          slot="graphic"
                          shape="square"
                          icon={ channel.listButton.iconName }
                          class={ channel.listButton.iconClassName }
                        />
                        <div
                          className="text-link text-lg"
                          slot="headline"
                        >
                          <FormattedMessage
                            id={ channel.title.id }
                            defaultMessage={ channel.title.defaultMessage }
                            description={ channel.title.description }
                          />
                        </div>
                        <p
                          slot="supporting-content"
                          className="m-0 text-lg pt-2"
                        >
                          <FormattedMessage
                            id={ channel.subtitle.id }
                            defaultMessage={ channel.subtitle.defaultMessage }
                            description={ channel.subtitle.description }
                          />
                        </p>
                        <SdfIcon
                          slot="action"
                          icon="nav-page-next"
                          class="text-2xl text-link"
                        />
                      </SdfFlagLayout>
                    </SdfBox>
                    ) }
                    { (item.type === 'EMAIL' || item.type === 'MOBILE') && (
                    <SdfBox
                      id={ channel.listButton.id }
                      data-testid={ channel.listButton.id }
                      style={ { cursor: 'pointer' } }
                      variant="shadowed"
                      role="button"
                      tabIndex={ 0 }
                      class="supports-focus-visible rounded-xl hydrated box-bordered hover:bg-action-primary-50 focus:bg-action-primary-50"
                      onClick={ () => {
                        onSendOTP(item.authenticatorId, item.type, item[channel.communicator]);
                      } }
                      onKeyDown={ (event) => {
                        isEnterKeyPressed(event.key) &&
                              onSendOTP(
                                item.authenticatorId,
                                item.type,
                                item[channel.communicator],
                              );
                      } }
                    >
                      <SdfFlagLayout>
                        <SdfIcon
                          slot="graphic"
                          shape="square"
                          icon={ channel.listButton.iconName }
                          class={ channel.listButton.iconClassName }
                        />
                        <div
                          className="text-link"
                          slot="headline"
                        >
                          <FormattedMessage
                            id={ channel.title.id }
                            defaultMessage={ channel.title.defaultMessage }
                            description={ channel.title.description }
                          />
                        </div>
                        <p
                          slot="supporting-content"
                          className="m-0 text-lg pt-2"
                        >
                          { item[channel.communicator] }
                        </p>
                        <SdfIcon
                          slot="action"
                          icon="nav-page-next"
                          class="text-2xl text-link"
                        />
                      </SdfFlagLayout>
                    </SdfBox>
                    ) }
                  </div>
                ))) }
              </SdfBoxStack>
            </div>
          </div>
        ) }
      </div>
    </div>
  );
};

ChannelListForm.propTypes = {
  challenge: PropTypes.shape({
    type: PropTypes.string,
    authenticators: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        authenticatorId: PropTypes.string,
        address: PropTypes.string,
      }),
    ),
  }),
  channelsDisplayChallenge: PropTypes.shape({
    type: PropTypes.string,
    authenticators: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        authenticatorId: PropTypes.string,
        address: PropTypes.string,
      }),
    ),
  }),
  sendOTP: PropTypes.func.isRequired,
  channelsDisplaySession: PropTypes.string,
  currentlySending: PropTypes.bool,
  isAppView: PropTypes.bool,
};

export default ChannelListForm;
