import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SdfIcon, SdfAlert } from '@waypoint/react-components';
import { isNotBlank } from '../../util/misc';
import { checkforErrors } from './password-strength-rules';
import {
  strong,
  veryStrong,
  strongest,
  modifierLC,
  modifierUC,
  modifierSC,
  modifierNum,
} from './indicator-constants';

const Passwordstrengthindicator = (props) => {
  const {
    password, strengthText, strengthTextInfoPrompts, validPassword,
  } = props;

  const availablePasswordRules = {
    '1Upper': {
      message: 'At least one letter uppercase',
      strengthIndicator: modifierUC,
    },
    '1Lower': {
      message: 'At least one lower character',
      strengthIndicator: modifierLC,
    },
    '1number': {
      message: 'At least one number',
      strengthIndicator: modifierNum,
    },
    '1Special': {
      message: 'At least one special character',
      strengthIndicator: modifierSC,
    },
  };

  const mapValidationMessage = rule => (
    <div className="flex flex-row w-full content-center">
      { checkforErrors(password).length > 0 ? (
        <SdfIcon
          icon="media-stop"
          className="text-quaternary"
        />
      ) : (
        <SdfIcon
          icon={
            strengthText.includes(rule) ||
            strengthTextInfoPrompts.includes(availablePasswordRules[rule].strengthIndicator) ?
              'media-stop' :
              'action-confirm'
          }
          className={
            strengthText.includes(rule) ||
            strengthTextInfoPrompts.includes(availablePasswordRules[rule].strengthIndicator) ?
              'text-quaternary' :
              'text-action-confirm'
          }
        />
      ) }

      <label className="pl-3 m-0 content-center">
        <FormattedMessage
          id={ `availablePasswordRules.${rule}` }
          defaultMessage={ availablePasswordRules[rule].message }
          description={ `${availablePasswordRules[rule].message}label` }
        />
      </label>
    </div>
  );

  const getStrengthMessageKey = () => {
    if (strengthText.includes(strongest)) return `changepassword.${strongest}`;
    if (strengthText.includes(veryStrong)) return `changepassword.${veryStrong}`;
    if (strengthText.includes(strong)) return `changepassword.${strong}`;
    return 'changepassword.normal';
  };

  let data;

  checkforErrors(password).map((error) => {
    data = !data ? error : data;
  });

  return (
    <>
      { isNotBlank(password) && (
        <div className="mt-2">
          { checkforErrors(password).length > 0 ? (
            <SdfAlert
              id="errorMessage"
              type="inline"
              slot="alert"
              status="error"
            >
              <FormattedMessage
                id={ `passwordStrengthIndicator.${data}` }
                defaultMessage={ `passwordStrengthIndicator.${data}` }
                description="Validation errors label"
              />
            </SdfAlert>
          ) : (
            <>
              { validPassword && (
                <p
                  id="password_requirements"
                  className="text-action-confirm font-bold mb-0"
                >
                  <FormattedMessage
                    id="changepassword.requirements"
                    defaultMessage="requirements chracters"
                    description="requirements label"
                  >
                    { message => (
                      <>
                        <span className="pr-1">{ message }</span>
                        { validPassword && (
                          <FormattedMessage
                            id="changepassword.met"
                            defaultMessage="met"
                            description="met label"
                          />
                        ) }
                      </>
                    ) }
                  </FormattedMessage>
                </p>
              ) }
              { validPassword && (
                <p
                  id="password_strength"
                  className="mb-0 text-action-confirm"
                >
                  <FormattedMessage
                    id="changepassword.password_strength"
                    defaultMessage="Password Strength"
                    description="Password strength label"
                  >
                    { message => (
                      <>
                        <span className="pr-1">{ message }</span>
                        <span className="font-bold">
                          <FormattedMessage
                            id={ getStrengthMessageKey() }
                            defaultMessage="Password Strength"
                            description="Password strength label"
                          />
                        </span>
                      </>
                    ) }
                  </FormattedMessage>
                </p>
              ) }
            </>
          ) }
        </div>
      ) }

      <div className="flex flex-col mt-2 space-y-2 text-sm">
        { checkforErrors(password).length === 0 && !validPassword && (
          <div id="minimumrequirements">
            <FormattedMessage
              id="changepassword.minimumrequirements"
              defaultMessage="Password requires 8 characters and at least 3 of the following:"
              description="Password requirements label"
            />
          </div>
        ) }
        { Object.keys(availablePasswordRules).map(rule => mapValidationMessage(rule)) }
      </div>
    </>
  );
};

Passwordstrengthindicator.propTypes = {
  password: PropTypes.string,
  strengthText: PropTypes.string,
  strengthTextInfoPrompts: PropTypes.string,
  validPassword: PropTypes.string,
};

export default Passwordstrengthindicator;
