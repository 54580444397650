import React from 'react';
import PropTypes from 'prop-types';
import { SdfButton, SdfInput, SdfIcon } from '@waypoint/react-components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { constants } from '../login/login-comp-constants';
import { Passwordstrengthindicator } from '../password-strength-indicator';
import {
  checkforErrors,
  minimumRequirements,
} from '../password-strength-indicator/password-strength-rules';
import { validatePassword } from '../password-strength-indicator/password-rules';
import { isNotBlank, setFocusOnInput } from '../../util/misc';

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.currentPasswordRef = React.createRef();
    this.newPasswordRef = React.createRef();
    this.confirmPasswordRef = React.createRef();
    this.focusCurrentPasswordRef = false;
    this.focusNewPasswordRef = false;
    this.focusConfirmPasswordRef = false;
    this.state = {
      password: '',
      strengthText: 'passwordStrengthIndicator.min8char_1number_1Upper_1Lower_1Special',
      strengthTextInfoPrompts: 'passwordStrengthIndicator.nothing',
      strengthTextColor: '#4a4a4a',
      strengthBarColor: 'none',
      validConfirmPassword: false,
      strengthWidth: 0,
      hasNoError: true,
      validPassword: false,
      isInvalidConfirmPassword: false,
    };
  }

  componentDidMount() {
    const inputEl = document.getElementById('change_pwd_form_current_password');

    inputEl && setFocusOnInput(inputEl);
  }

  componentDidUpdate() {
    if (this.focusCurrentPasswordRef && this.currentPasswordRef.current) {
      this.currentPasswordRef.current.setFocus();
      this.focusCurrentPasswordRef = false;
    }

    if (this.focusNewPasswordRef && this.newPasswordRef.current) {
      this.newPasswordRef.current.setFocus();
      this.focusNewPasswordRef = false;
    }

    if (this.focusConfirmPasswordRef && this.confirmPasswordRef.current) {
      this.confirmPasswordRef.current.setFocus();
      this.focusConfirmPasswordRef = false;
    }
  }

  render() {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      currentlySending,
      passwordStatus,
      currentPwdCheck,
      changePasswordCancel,
      intl,
      setCurrentPassword,
      setNewPassword,
      setConfirmPassword,
      changePassword,
      isAppView,
      setValidationError,
      changePasswordRemindMeLater,
    } = this.props;

    const {
      ENTER_KEY_CODE,
      STATUS_EXPIRED,
      STATUS_ABOUT_TO_EXPIRE,
      STATUS_RESET,
      MOUSE_CLICK,
      CURRENT_PASSWORD_AUTO_FOCUS,
      NEW_PASSWORD_AUTO_FOCUS,
      CONFIRM_PASSWORD_AUTO_FOCUS,
    } = constants;
    const {
      password,
      validPassword,
      validConfirmPassword,
      isBlurNewPassword,
      isBlurCurrentPassword,
      isInvalidConfirmPassword,
    } = this.state;

    const onBlurNewPassword = () => {
      this.setState({
        isBlurNewPassword: true,
      });
      if (!isValidNewPassword) {
        this.setState({
          validPassword: false,
        });
        isNotBlank(newPassword) && setValidationError('INVALID_NEW_PASSWORD');
      }
    };

    const isValidNewPassword = newPassword !== currentPassword;
    const cssValidationNewPassword =
      isBlurCurrentPassword && isBlurNewPassword && isNotBlank(newPassword) && isValidNewPassword;

    const onBlurCurrentPassword = () => {
      this.setState({
        isBlurCurrentPassword: true,
      });
      if (!isValidNewPassword) {
        this.setState({
          validPassword: false,
        });
        isNotBlank(currentPassword) && setValidationError('INVALID_NEW_PASSWORD');
      } else {
        this.setState({
          validPassword: !!(newPassword && minimumRequirements(newPassword)),
        });
        if (confirmPassword && confirmPassword !== newPassword) {
          if (isAppView) {
            setValidationError('PASSWORD_NON_MATCH');
          }
          this.setState({
            validConfirmPassword: false,
          });
        }
      }
    };

    const onCurrentPasswordChange = ({ detail }) => {
      this.setState({
        isBlurCurrentPassword: false,
      });
      setCurrentPassword(detail);
      if (confirmPassword && confirmPassword !== password) {
        if (isAppView) {
          setValidationError('PASSWORD_NON_MATCH');
        }
        this.setState({
          validConfirmPassword: false,
        });
      }
    };

    const onPasswordChange = ({ detail }) => {
      const obj = validatePassword(detail);

      this.setState({
        password: detail,
        strengthText: `passwordStrengthIndicator.${obj.strengthTextVal}`,
        strengthTextInfoPrompts: `passwordStrengthIndicator.${obj.strengthTextInfoPromptsVal}`,
        strengthTextColor: obj.strengthTextColorVal,
        strengthBarColor: obj.strengthBarColorVal,
        strengthWidth: obj.strengthWidthVal,
        hasNoError: typeof obj.hasError === 'undefined',
        validPassword: typeof obj.hasError === 'undefined' && minimumRequirements(detail),
        isBlurNewPassword: false,
      });

      setNewPassword(detail);
      if (confirmPassword && confirmPassword !== detail) {
        this.setState({
          validConfirmPassword: false,
        });
      } else if (confirmPassword && confirmPassword === detail) {
        this.setState({
          validConfirmPassword: true,
        });
      }
    };

    const onConfirmPasswordChange = ({ detail }) => {
      setConfirmPassword(detail);
      if (newPassword === detail) {
        this.setState({
          validConfirmPassword: true,
        });
      } else {
        this.setState({
          validConfirmPassword: false,
        });
      }
    };

    const isPasswordExpired =
      isNotBlank(passwordStatus) &&
      (passwordStatus === STATUS_EXPIRED || passwordStatus === STATUS_RESET);
    const isPasswordToExpire =
      !currentlySending && isNotBlank(passwordStatus) && passwordStatus === STATUS_ABOUT_TO_EXPIRE;

    const autoFocus = (currentStep) => {
      if (currentStep == CURRENT_PASSWORD_AUTO_FOCUS) {
        this.focusNewPasswordRef = true;
      } else if (currentStep == NEW_PASSWORD_AUTO_FOCUS) {
        this.focusConfirmPasswordRef = true;
      }
    };

    const isPasswordEntered =
      isNotBlank(currentPassword) &&
      isNotBlank(newPassword) &&
      isNotBlank(confirmPassword) &&
      validPassword;

    const changeFocus = (event, currentStep) => {
      if (event.charCode === ENTER_KEY_CODE || event.button === MOUSE_CLICK) {
        autoFocus(currentStep);
      }
    };

    const onChangePasswordCancel = (event) => {
      if (event.charCode === ENTER_KEY_CODE || event.button === MOUSE_CLICK) {
        !currentlySending && changePasswordCancel();
      }
    };

    const onChangePasswordSubmit = (event) => {
      /* TBD - Remind me later link using cancel for now. Will be changed
      when change password API is ready */
      if (event.charCode === ENTER_KEY_CODE || event.button === MOUSE_CLICK) {
        isPasswordEntered && validConfirmPassword && !currentlySending && changePassword();
      }
    };

    const onChangePasswordRemindMeLater = (event) => {
      /* TBD - Remind me later link using cancel for now. Will be changed
      when authentication API is ready */
      if (event.charCode === ENTER_KEY_CODE || event.button === MOUSE_CLICK) {
        !currentlySending && changePasswordRemindMeLater();
      }
    };

    const getConfirmPasswordCss = () => {
      if (cssValidationNewPassword === undefined) return 'default';
      if (checkforErrors(password).length > 0) return 'error';
      return 'default';
    };

    const getPasswordValidationIcon = () => {
      if (cssValidationNewPassword === undefined) return null;

      if (cssValidationNewPassword && validPassword) {
        return (
          <SdfIcon
            slot="after"
            className="text-action-confirm"
            icon="action-confirm"
          />
        );
      }
      return (
        <SdfIcon
          slot="after"
          icon="action-close"
        />
      );
    };

    const isConfirmPasswordDisabled = () => {
      if (isBlurNewPassword) {
        return !(isValidNewPassword && isNotBlank(newPassword) && minimumRequirements(newPassword));
      }
      return !(validPassword && minimumRequirements(newPassword));
      //! (validConfirmPassword && isPasswordEntered) ? true : undefined
    };

    const isConfirmPasswordValid = () => validPassword && validConfirmPassword;

    const getPasswordConfirmState = () => {
      if (confirmPassword === '') return 'default';
      if (!isConfirmPasswordValid() && isInvalidConfirmPassword) return 'error';
      return 'default';
    };

    const getConfirmPasswordIcon = () => {
      if (!isNotBlank(confirmPassword)) return null;

      return isConfirmPasswordValid() ? (
        <SdfIcon
          slot="after"
          className="text-action-confirm"
          icon="action-confirm"
        />
      ) : (
        <SdfIcon
          slot="after"
          icon="action-close"
        />
      );
    };

    const canShowInvalidConfirmPassword = () => {
      if (!isNotBlank(confirmPassword)) {
        this.setState({ isInvalidConfirmPassword: false });
      } else if (isConfirmPasswordValid()) {
        this.setState({ isInvalidConfirmPassword: false });
      } else {
        if (isAppView) {
          setValidationError('PASSWORD_NON_MATCH');
        }
        this.setState({ isInvalidConfirmPassword: true });
      }
    };

    return (
      <>
        <div className="mt-4 md:flex-row mb-4 text-md">
          <FormattedMessage
            id="changepassword.instruction_text"
            defaultMessage="Change password"
            description="Change password label"
          />
        </div>
        <form>
          <div className="flex flex-row justify-center">
            <div className={ isAppView ? 'w-full' : 'w-full max-w-xs' }>
              <div className="mt-3">
                <SdfInput
                  id="change_pwd_form_current_password"
                  input-width="full"
                  class="w-full"
                  ref={ this.currentPasswordRef }
                  state={ currentPwdCheck }
                  value={ currentPassword }
                  type="password"
                  label={ intl.formatMessage({
                    id: 'changepassword.old_password',
                    defaultMessage: 'Current Password',
                    description: 'Current Password label',
                  }) }
                  onKeyPress={ (eve) => {
                    onChangePasswordSubmit(eve);
                    changeFocus(eve, CURRENT_PASSWORD_AUTO_FOCUS);
                  } }
                  onSdfChange={ onCurrentPasswordChange }
                  onBlur={ onBlurCurrentPassword }
                />
                { currentPwdCheck === 'error' && (
                  <span>
                    <sdf-alert
                      id="error-information"
                      type="inline"
                      size="sm"
                      status="error"
                      emphasis="primary"
                    >
                      <FormattedMessage
                        id="INVALID_CURRENT_PASSWORD"
                        defaultMessage="Your current password is invalid. Please try again."
                        description="Incorrect current password entered."
                      />
                    </sdf-alert>
                  </span>
                ) }
              </div>

              <div className="mt-6">
                <SdfInput
                  id="change_pwd_form_new_password"
                  type="password"
                  input-width="full"
                  class="w-full"
                  ref={ this.newPasswordRef }
                  label={ intl.formatMessage({
                    id: 'changepassword_new_password',
                    defaultMessage: 'New Password',
                    description: 'New Password label',
                  }) }
                  value={ newPassword }
                  onSdfInput={ onPasswordChange }
                  onSdfChange={ onPasswordChange }
                  onKeyPress={ eve => changeFocus(eve, NEW_PASSWORD_AUTO_FOCUS) }
                  onBlur={ onBlurNewPassword }
                  state={ getConfirmPasswordCss() }
                  aria-describedby="password_rule"
                >
                  { getPasswordValidationIcon() }
                </SdfInput>

                <Passwordstrengthindicator { ...this.state } />
              </div>

              <div className="mt-6 mb-3 md:mb-0">
                <SdfInput
                  id="change_pwd_form_confirm_password"
                  type="password"
                  value={ confirmPassword }
                  input-width="full"
                  class="w-full"
                  label={ intl.formatMessage({
                    id: 'changepassword_confirm_password',
                    defaultMessage: 'Confirm Password',
                    description: 'Confirm Password label',
                  }) }
                  ref={ this.confirmPasswordRef }
                  name="confirmPasswordName"
                  onSdfInput={ onConfirmPasswordChange }
                  onSdfChange={ onConfirmPasswordChange }
                  onKeyPress={ (eve) => {
                    onChangePasswordSubmit(eve);
                    changeFocus(eve, CONFIRM_PASSWORD_AUTO_FOCUS);
                  } }
                  onBlur={ canShowInvalidConfirmPassword }
                  state={ getPasswordConfirmState() }
                  disabled={ isConfirmPasswordDisabled() ? true : null }
                >
                  { getConfirmPasswordIcon() }
                </SdfInput>
                { getPasswordConfirmState() === 'error' && !isAppView && (
                  <span>
                    <sdf-alert
                      id="error-information"
                      type="inline"
                      size="sm"
                      status="error"
                      emphasis="primary"
                    >
                      <FormattedMessage
                        id="changepassword.invalid_confirm_password"
                        defaultMessage="New password and confirm new password must match"
                        description="New password and confirm new password must match."
                      />
                    </sdf-alert>
                  </span>
                ) }
              </div>
            </div>
          </div>
          <div
            className={
              !isAppView ?
                'flex md:flex-row md:justify-end flex-col-reverse items-center mt-6 md:mt-12' :
                'flex flex-col-reverse'
            }
          >
            <SdfButton
              id="changePwdCancelLink"
              action="standard"
              class={
                !isAppView ?
                  'w-full max-w-xs md:min-w-32 md:w-auto mt-4 md:mt-0 mb-12 md:mb-0' :
                  'w-full mt-4'
              }
              onClick={ onChangePasswordCancel }
              onKeyPress={ onChangePasswordCancel }
              emphasis="secondary"
              hidden={ !isPasswordExpired }
              size="lg"
            >
              <FormattedMessage
                id="olp_cancel"
                defaultMessage="CANCEL"
                description="CANCEL button"
              />
            </SdfButton>

            <SdfButton
              className={
                isAppView ?
                  'w-full mt-4' :
                  'w-full max-w-xs md:min-w-32 md:w-auto mt-4 md:mt-0 mb-12 md:mb-0'
              }
              id="changeRemindMeLaterLink"
              action="standard"
              onClick={ onChangePasswordRemindMeLater }
              onKeyPress={ onChangePasswordRemindMeLater }
              emphasis="tertiary"
              hidden={ !isPasswordToExpire }
              size="lg"
            >
              <FormattedMessage
                id="changepassword.remind_me_later_link"
                defaultMessage="Remind me later"
                description="Remind me later button"
              />
            </SdfButton>

            <SdfButton
              id="changePwdSaveBtn"
              class={ isAppView ? 'w-full mt-4' : 'md:ms-4 w-full max-w-xs md:min-w-32 md:w-auto' }
              emphasis="primary"
              action="standard"
              onClick={ onChangePasswordSubmit }
              onKeyPress={ onChangePasswordSubmit }
              disabled={ !(validConfirmPassword && isPasswordEntered) ? true : undefined }
              size="lg"
            >
              <FormattedMessage
                id="olp_save"
                defaultMessage="Save"
                description="Save button"
              />
            </SdfButton>
          </div>
        </form>
      </>
    );
  }
}

ChangePasswordForm.propTypes = {
  currentPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  currentlySending: PropTypes.bool,
  passwordStatus: PropTypes.string,
  changePasswordCancel: PropTypes.func.isRequired,
  currentPwdCheck: PropTypes.func,
  setCurrentPassword: PropTypes.func.isRequired,
  isAppView: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  setNewPassword: PropTypes.func.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
  changePasswordRemindMeLater: PropTypes.func.isRequired,
};

ChangePasswordForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ChangePasswordForm);
