import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  SdfButton, SdfIcon, SdfPromoBox, SdfBadge,
} from '@waypoint/react-components';
import { constants } from './channel-collection-constants';
import { isEnterKeyPressed } from '../../util/misc';

const { EMAIL } = constants;

const EmailActivationPage = (props) => {
  const {
    emailActivationAcknowledged,
    currentlySending,
    emailAddress,
    expireTime,
    intl,
    formattedChannelInfo,
    isAppView,
  } = props;

  const getActivationMessage = () => {
    const localeData = intl.formatMessage({
      id: 'channelcollection_email_success_text',
      defaultMessage:
        'To save your email address, check your email account and respond to the activation message from ADP within the next [X] hours.',
    });

    return localeData.replace('[X]', expireTime);
  };

  // eslint-disable-next-line max-len
  const getFormattedSubHeaderMessage = () => formattedChannelInfo && formattedChannelInfo.type === EMAIL ?
    intl.formatMessage({
      id: 'channelcollection_mobile_success_headline',
      defaultMessage: 'Success!',
    }) :
    intl.formatMessage({
      id: 'ChannelCollection_email_check',
      defaultMessage: 'Check your email',
    });

  const getFormattedMessage = () => {
    const activationMessage = getActivationMessage();

    return formattedChannelInfo && formattedChannelInfo.type === EMAIL ?
      intl.formatMessage({
        id: 'channelcollection_email_success_subheadline',
        defaultMessage: 'Your email address has been saved.',
      }) :
      activationMessage;
  };

  return (
    <div id="email-activation-page">
      <form>
        <div className="flex justify-center">
          { formattedChannelInfo && formattedChannelInfo.type === EMAIL ? (
            <SdfPromoBox
              variant="clear"
              align="center"
              size="lg"
              header={ getFormattedSubHeaderMessage() }
              message={ getFormattedMessage() }
              illustrationName="checkmark"
            />
          ) : (
            <SdfPromoBox
              variant="clear"
              align="center"
              size="lg"
              header={ getFormattedSubHeaderMessage() }
              message={ getFormattedMessage() }
              illustrationName="envelope-open"
            />
          ) }
        </div>
        <div className="flex justify-center -mt-4">
          <SdfBadge status="in-progress">
            <SdfIcon
              icon="action-email"
              tabindex="0"
              icon-title={ intl.formatMessage({
                id: 'email_icon_title',
                defaultMessage: 'email-icon',
              }) }
            />
            { emailAddress }
          </SdfBadge>
        </div>
        <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
          <SdfButton
            id="continue-to-app"
            class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto mt-2 md:mt-0' }
            size="lg"
            emphasis="primary"
            onClick={ () => {
              !currentlySending && emailActivationAcknowledged();
            } }
            onKeyPress={ (evt) => {
              !currentlySending && isEnterKeyPressed(evt.key) && emailActivationAcknowledged();
            } }
          >
            <FormattedMessage
              id="general_continue"
              defaultMessage="Continue"
            />
          </SdfButton>
        </div>
      </form>
    </div>
  );
};

EmailActivationPage.propTypes = {
  emailActivationAcknowledged: PropTypes.func,
  currentlySending: PropTypes.bool,
  isAppView: PropTypes.bool,
  emailAddress: PropTypes.string,
  formattedChannelInfo: PropTypes.shape({
    type: PropTypes.string,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  expireTime: PropTypes.number,
};
export default injectIntl(EmailActivationPage);
