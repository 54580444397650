import React from 'react';
import PropTypes from 'prop-types';
import { SdfButton } from '@waypoint/react-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { constants } from '../login/login-comp-constants';
import {
  getCookie,
  removeCookie,
  getOrganizationIdForBranding,
  getParameterByName,
  PRODUCT_ID,
  isLocalStorageSupported,
  buildFedTargetUrl,
  SPI_CLIENT_NAME,
  SPI_MOBI_FED_URL,
  setFocusOnInput,
} from '../../util/misc';

const { COOKIE_GREET_FNAME, COOKIE_REMEMBER_ME, ALP_COOKIE_REMEMBER_ME } = constants;

const productId = getParameterByName(PRODUCT_ID) || '';
const organizationId = getOrganizationIdForBranding();
const fedTargetURL = buildFedTargetUrl();

class UserTypeMessage extends React.Component {
  constructor(props) {
    super(props);
    this.onSignUpRedirect = this.onSignUpRedirect.bind(this);
  }

  onSignUpRedirect = () => {
    const userFName = getCookie(COOKIE_GREET_FNAME);
    const {
      setUserid,
      setRememberCheckBox,
      changeAdpUserId,
      changeUserFname,
      onSelectDiffUser,
      diffUserAction,
      isApp,
    } = this.props;

    if (userFName) {
      removeCookie(COOKIE_GREET_FNAME);
      removeCookie(COOKIE_REMEMBER_ME);
      removeCookie(ALP_COOKIE_REMEMBER_ME);
      setUserid('');
      setRememberCheckBox(false);
      !isApp && diffUserAction();
      changeAdpUserId('');
      changeUserFname('');
      isLocalStorageSupported(true) && localStorage.removeItem(SPI_CLIENT_NAME);
      isLocalStorageSupported(true) && localStorage.removeItem(SPI_MOBI_FED_URL);
      onSelectDiffUser && onSelectDiffUser();
      const inputEl = document.getElementById('login-form_username');

      inputEl && setFocusOnInput(inputEl);
    } else {
      const {
        signUp,
        returnURL,
        backgroundImage,
        callingAppID,
        organizationLogo,
        productLogo,
        formRedirectURL,
        empTargetUrl,
        adminTargetUrl,
      } = this.props;

      const params = {
        callingAppId: callingAppID,
        returnUrl: returnURL,
        rtnURL: returnURL,
        action: signUp.url,
        method: signUp.method,
        backgroundImageUrl: backgroundImage,
        productLogoUrl: productLogo,
        organizationLogoUrl: organizationLogo,
        productId,
        organizationId,
        fedTargetURL,
        empTargetUrl,
        adminTargetUrl,
      };

      formRedirectURL(params);
    }
  };

  render() {
    const {
      hyperLink, userFName, isApp, intl,
    } = this.props;
    const {
      hyperLinkDescription,
      hyperLinkID,
      hyperLinkdefaultMessage,
      hyperLinkDescriptionPre,
      hyperLinkIDPre,
      hyperLinkdefaultMessagePre,
    } = hyperLink;

    const firstNameUser = userFName;

    return (
      <div
        className={ `flex justify-center pt-1 flex-wrap ${isApp ? 'mt-6 mb-4 pt-2' : 'mt-8 mb-3'}` }
      >
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
        <div
          id={ hyperLinkIDPre }
          className="text-lg flex justify-center self-center max-w-full"
        >
          <FormattedMessage
            id={ hyperLinkIDPre }
            defaultMessage={ hyperLinkdefaultMessagePre }
            description={ hyperLinkDescriptionPre }
          />
          &nbsp;
          <span className="overflow-ellipsis overflow-hidden">{ firstNameUser }</span>
          <span>?</span>
        </div>
        <div className="text-center ms-3">
          <SdfButton
            id={ hyperLinkID }
            emphasis="tertiary"
            className="text-lg font-medium"
            onClick={ this.onSignUpRedirect }
            variant="text"
            button-title={ intl.formatMessage({
              id: hyperLinkID,
              defaultMessage: hyperLinkdefaultMessage,
            }) }
          >
            <FormattedMessage
              id={ hyperLinkID }
              defaultMessage={ hyperLinkdefaultMessage }
              description={ hyperLinkDescription }
            />
          </SdfButton>
        </div>
      </div>
    );
  }
}

UserTypeMessage.propTypes = {
  signUp: PropTypes.shape({
    url: PropTypes.string,
    method: PropTypes.string,
  }),
  returnURL: PropTypes.string,
  backgroundImage: PropTypes.string,
  callingAppID: PropTypes.string,
  organizationLogo: PropTypes.string,
  productLogo: PropTypes.string,
  userFName: PropTypes.string,
  changeUserFname: PropTypes.func,
  onSelectDiffUser: PropTypes.func,
  diffUserAction: PropTypes.func,
  setUserid: PropTypes.func.isRequired,
  setRememberCheckBox: PropTypes.func.isRequired,
  formRedirectURL: PropTypes.func,
  changeAdpUserId: PropTypes.func,
  hyperLink: PropTypes.shape({
    hyperLinkDescription: PropTypes.string,
    hyperLinkID: PropTypes.string,
    hyperLinkdefaultMessage: PropTypes.string,
    hyperLinkDescriptionPre: PropTypes.string,
    hyperLinkIDPre: PropTypes.string,
    hyperLinkdefaultMessagePre: PropTypes.string,
  }),
  isApp: PropTypes.bool,
  empTargetUrl: PropTypes.string,
  adminTargetUrl: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(UserTypeMessage);
