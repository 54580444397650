import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SdfButton, SdfPromoBox } from '@waypoint/react-components';
import {
  isLandlineCollection,
  isVoiceBioMetricsCollection,
  isEnterKeyPressed,
} from '../../util/misc';

const ChannelCollectionSuccess = (props) => {
  const {
    acknowledgeSuccess,
    currentlySending,
    channelCollectionChallenge,
    landlineAcknowledged,
    voiceBioMetricsAcknowledged,
    intl,
    isAppView,
  } = props;

  const { communicationCollectionType } = channelCollectionChallenge;

  const getFormattedHeaderMessage = () => {
    if (isVoiceBioMetricsCollection(communicationCollectionType)) {
      return intl.formatMessage({
        id: 'channelcollection_voicebiometrics_success_headline',
        defaultMessage: "You're All Set",
      });
    }

    return intl.formatMessage({
      id: 'channelcollection_mobile_success_headline',
      defaultMessage: 'Success!',
    });
  };

  const getFormattedMessage = () => {
    if (isVoiceBioMetricsCollection(communicationCollectionType)) {
      return intl.formatMessage({
        id: 'channelcollection_voicebiometrics_success_subheadline',
        defaultMessage: "The next time you call ADP we'll capture your unique voice pattern.",
      });
    }

    if (isLandlineCollection(communicationCollectionType)) {
      return intl.formatMessage({
        id: 'channelcollection_landline_success_subheadline',
        defaultMessage: 'Your secondary phone number has been saved.',
      });
    }

    return intl.formatMessage({
      id: 'channelcollection_mobile_success_subheadline',
      defaultMessage: 'Your mobile number has been saved.',
    });
  };

  const handleContinue = () => {
    if (!currentlySending && isLandlineCollection(communicationCollectionType)) {
      landlineAcknowledged();
    } else if (!currentlySending && isVoiceBioMetricsCollection(communicationCollectionType)) {
      voiceBioMetricsAcknowledged();
    } else {
      acknowledgeSuccess();
    }
  };

  const handleContinueButtonClick = () => {
    handleContinue();
  };

  const handleContinueButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleContinue();
    }
  };

  return (
    <div
      id="channel-collection-server-success"
      className="flex flex-col"
    >
      <div className="flex justify-center">
        <SdfPromoBox
          variant="clear"
          align="center"
          size="lg"
          header={ getFormattedHeaderMessage() }
          message={ getFormattedMessage() }
          illustrationName="checkmark"
        />
      </div>
      <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
        <SdfButton
          id="saveChannelCollection"
          className={ isAppView ? 'w-full' : 'md:pl-4 max-w-xs w-full md:w-auto' }
          emphasis="primary"
          size="lg"
          onClick={ handleContinueButtonClick }
          onKeyPress={ handleContinueButtonKeyPress }
        >
          <FormattedMessage
            id="general_continue"
            defaultMessage="Continue"
          />
        </SdfButton>
      </div>
    </div>
  );
};

ChannelCollectionSuccess.propTypes = {
  acknowledgeSuccess: PropTypes.func,
  landlineAcknowledged: PropTypes.func,
  voiceBioMetricsAcknowledged: PropTypes.func,
  currentlySending: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  channelCollectionChallenge: PropTypes.shape({
    communicationCollectionType: PropTypes.string,
  }),
  isAppView: PropTypes.bool,
};
export default injectIntl(ChannelCollectionSuccess);
