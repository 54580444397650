/* eslint-disable no-nested-ternary */
import React from 'react';
import { intlShape, FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SdfButton } from '@waypoint/react-components';
import { constants } from './channel-collection-constants';
import {
  isNotBlank,
  isBlank,
  isMobileCollection,
  isMobileVerification,
  isPeriodicPrompt,
  isEnterKeyPressed,
} from '../../util/misc';
import { ChannelCollectionError, PasscodePageLayout, ChannelCollectionMobile } from '.';

const { MOBILE, PERSONAL } = constants;

const MobileCollectionForm = (props) => {
  const {
    saveCommunicationChannel,
    currentlySending,
    channelCollectionFailed,
    channelCollectionPassed,
    skipChannelCollection,
    mobile,
    consent,
    channelCollectionChallenge,
    mobileValid,
    isAppView,
  } = props;

  const { communicationCollectionType } = channelCollectionChallenge;

  const onSave = () => {
    const addedCommunications = [];
    const changedCommunications = [];

    if (isMobileCollection(communicationCollectionType)) {
      mobile &&
        isNotBlank(mobile.mobileNumber) &&
        addedCommunications.push({
          type: MOBILE,
          countryCode: mobile && mobile.countryCode,
          mobileNumber: mobile && mobile.mobileNumber,
          usageType: PERSONAL,
          consentStatus: consent,
          country: mobile && mobile.country,
        });
    } else if (
      isMobileVerification(communicationCollectionType) ||
      isPeriodicPrompt(communicationCollectionType)
    ) {
      mobile &&
        isNotBlank(mobile.mobileNumber) &&
        changedCommunications.push({
          type: MOBILE,
          countryCode: mobile && mobile.countryCode,
          mobileNumber: mobile && mobile.mobileNumber,
          usageType: PERSONAL,
          consentStatus: consent,
          country: mobile && mobile.country,
          communicationId: mobile && mobile.communicationId,
        });
    }

    saveCommunicationChannel(addedCommunications, changedCommunications);
  };

  const handleVerify = () => {
    !isSaveButtonDisabled && onSave();
  };

  const handleVerifyButtonClick = () => {
    handleVerify();
  };

  const handleVerifyButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleVerify();
    }
  };

  const handleRemineMe = () => {
    !currentlySending && skipChannelCollection(true);
  };

  const handleRemindMeButtonClick = () => {
    handleRemineMe();
  };

  const handleRemindMeButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleRemineMe();
    }
  };

  const isSaveButtonDisabled = isBlank(mobile.mobileNumber) || !mobileValid || currentlySending;

  const getFormattedMessage = () => {
    if (isMobileCollection(communicationCollectionType)) {
      return (
        <div>
          <FormattedMessage
            id="channelcollection_mobile_primary"
            // eslint-disable-next-line max-len
            defaultMessage="Protect your account from malicious activity by providing your primary mobile number."
          />
        </div>
      );
    }
    if (isPeriodicPrompt(communicationCollectionType)) {
      return (
        <div>
          <FormattedMessage
            id="channelcollection_periodic_prompt"
            // eslint-disable-next-line max-len
            defaultMessage="For security reasons, verify the phone number we have on file and let us know if your contact information has changed."
          />
        </div>
      );
    }
    return (
      <div>
        <FormattedMessage
          id="channelcollection_verify_mobile"
          // eslint-disable-next-line max-len
          defaultMessage="To help protect your account we need to confirm your mobile phone number. We will send you a text message to verify it."
        />
      </div>
    );
  };

  return (
    (channelCollectionFailed && <ChannelCollectionError { ...props } />) ||
    (channelCollectionPassed && <PasscodePageLayout { ...props } />) || (
      <div id="channel-collection-mobile">
        <div className="font-normal text-md leading-md mt-2 md:mt-4 mb-4">
          { getFormattedMessage() }
        </div>
        <div className="flex flex-row justify-center">
          <div className={ isAppView ? 'w-full' : 'max-w-xs w-full md:max-w-full md:mx-20' }>
            <ChannelCollectionMobile
              { ...props }
              handleKeyPress={ handleVerifyButtonKeyPress }
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center md:justify-end mt-4 md:mt-8 gap">
          <SdfButton
            id="channel-collection-save"
            emphasis="primary"
            size="lg"
            className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
            disabled={ isSaveButtonDisabled || undefined }
            onClick={ handleVerifyButtonClick }
            onKeyPress={ handleVerifyButtonKeyPress }
          >
            <FormattedMessage
              id="channelcollection_verify_number_button_label"
              defaultMessage="Verify mobile number"
            />
          </SdfButton>

          <SdfButton
            id="channel-collection-remind"
            emphasis="secondary"
            size="lg"
            className={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto' }
            disabled={ currentlySending || undefined }
            onClick={ handleRemindMeButtonClick }
            onKeyPress={ handleRemindMeButtonKeyPress }
          >
            <FormattedMessage
              id="channelcollection_remindme_button_label"
              defaultMessage="Remind me later"
            />
          </SdfButton>
        </div>
      </div>
    )
  );
};

MobileCollectionForm.propTypes = {
  saveCommunicationChannel: PropTypes.func,
  currentlySending: PropTypes.bool,
  channelCollectionFailed: PropTypes.bool,
  channelCollectionChallenge: PropTypes.shape({
    communicationCollectionType: PropTypes.string,
  }),
  channelCollectionPassed: PropTypes.bool,
  skipChannelCollection: PropTypes.func,
  mobile: PropTypes.shape({
    country: PropTypes.string,
    communicationId: PropTypes.string,
    countryCode: PropTypes.string,
    mobileNumber: PropTypes.string,
  }),
  consent: PropTypes.string,
  mobileValid: PropTypes.bool,
  isAppView: PropTypes.bool,
};

MobileCollectionForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(MobileCollectionForm);
