import React from 'react';
import PropTypes from 'prop-types';
import ClientMobilePanel from './ClientMobilePanel';
import NotaClientPanel from './NotaClientPanel';
import NotaClientImagePanel from './NotaClientImagePanel';
import { constants } from './panel-constants';
import { getCookie, extractBrandingImageProperty } from '../../util/misc';

const {
  OLP_VIEW, NOT_A_CLIENT_IMAGE_PANEL, NOT_A_CLIENT_PANEL, CLIENT_MOBILE_PANEL,
} = constants;

class MainPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isMobileDevice,
      locale,
      clientPanel,
      redirectURL,
      clientPanelMobile,
      brandingStyles,
      isBrandingStylesLoaded,
      setBrandingStyles,
      setBrandingStylesLoaded,
    } = this.props;

    const {
      headlineText, bodyText, linkText, linkUrl, image: imageProp, backgroundColor,
    } =
      clientPanel || '';

    const {
      bodyText: mobilebodyText,
      buttonText: mobilebuttonText,
      buttonUrl: mobilebuttonUrl,
      headlineText: mobileheadlineText,
      cancelButtonText: mobilecancelButtonText,
    } = clientPanelMobile || '';

    const imageUrl = extractBrandingImageProperty(imageProp, locale);
    const imageAlignment =
      imageProp && imageProp.alignment ? imageProp.alignment.toUpperCase() : 'LEFT';
    const { weight: clientPanelHeaderTextStyle, color: clientPanelHeaderTextColor } =
      headlineText || '';

    const { i18n: mobilebodyLocaleProp } = mobilebodyText || '';

    const { i18n: mobilebuttonLocaleProp } = mobilebuttonText || '';

    const { i18n: mobileheadlineLocaleProp } = mobileheadlineText || '';

    const { i18n: mobilecancelButtonLocaleProp } = mobilecancelButtonText || '';

    const { weight: clientPanelBodyTextStyle, color: clientPanelBodyTextColor } = bodyText || '';

    const { i18n: headlineLocaleProp } = headlineText || '';

    const { i18n: linkTextLocaleProp } = linkText || '';

    const { i18n: bodyTextLocaleProp } = bodyText || '';

    const { i18n: linkUrlLocaleProp } = linkUrl || '';

    const styles = {
      clientPanel: {
        clientHeader: {
          color: clientPanelHeaderTextColor,
          fontWeight: clientPanelHeaderTextStyle,
        },
        clientBody: {
          color: clientPanelBodyTextColor,
          fontWeight: clientPanelBodyTextStyle,
        },
      },
    };

    if (!isBrandingStylesLoaded) {
      setBrandingStyles(styles);
      setBrandingStylesLoaded(true);
    }

    let logpanel;

    if (clientPanelMobile && isMobileDevice && !getCookie(OLP_VIEW)) {
      logpanel = CLIENT_MOBILE_PANEL;
    } else if (clientPanel && clientPanel.image && !isMobileDevice) {
      logpanel = NOT_A_CLIENT_IMAGE_PANEL;
    } else if (clientPanel && !clientPanel.image) {
      logpanel = NOT_A_CLIENT_PANEL;
    }

    return (
      <div
        style={ { flex: 1 } }
        className="mt-6"
      >
        <div id="loginPanel">
          { (() => {
            // eslint-disable-next-line default-case
            switch (logpanel) {
              case CLIENT_MOBILE_PANEL:
                return (
                  <div id="mobileClientPanelDiv">
                    <ClientMobilePanel
                      id="mobileClientPanel"
                      key="mobileClientPanel"
                      bodyText={ mobilebodyLocaleProp }
                      headlineText={ mobileheadlineLocaleProp }
                      buttonUrl={ mobilebuttonUrl }
                      buttonText={ mobilebuttonLocaleProp }
                      cancelButtonText={ mobilecancelButtonLocaleProp }
                      redirectURL={ redirectURL }
                      styles={ brandingStyles || {} }
                      locale={ locale }
                      { ...this.props }
                    />
                  </div>
                );
              case NOT_A_CLIENT_IMAGE_PANEL:
                return (
                  <div id="notaClientPanelDiv">
                    <hr className="border-0 border-t-2 border-solid border-dark" />
                    <NotaClientImagePanel
                      id="notaClientImagePanel"
                      key="notaClientImagePanel"
                      headlineText={ headlineLocaleProp }
                      bodyText={ bodyTextLocaleProp }
                      linkText={ linkTextLocaleProp }
                      linkUrl={ linkUrlLocaleProp }
                      imageUrl={ imageUrl }
                      backgroundColor={ backgroundColor }
                      imageAlignment={ imageAlignment }
                      redirectURL={ redirectURL }
                      styles={ brandingStyles || {} }
                      locale={ locale }
                    />
                  </div>
                );
              case NOT_A_CLIENT_PANEL:
                return (
                  <div id="notaClientPanelDiv">
                    <hr className="border-0 border-t-2 border-solid border-dark" />
                    <NotaClientPanel
                      id="notaClientPanel"
                      key="notaClientPanel"
                      headlineText={ headlineLocaleProp }
                      bodyText={ bodyTextLocaleProp }
                      linkText={ linkTextLocaleProp }
                      linkUrl={ linkUrlLocaleProp }
                      backgroundColor={ backgroundColor }
                      redirectURL={ redirectURL }
                      styles={ brandingStyles || {} }
                      locale={ locale }
                    />
                  </div>
                );
              default:
                return <></>;
            }
          })() }
        </div>
      </div>
    );
  }
}

MainPanel.propTypes = {
  isMobileDevice: PropTypes.bool,
  background: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  clientPanelMobile: PropTypes.shape({
    bodyText: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
    headlineText: PropTypes.string,
    cancelButtonText: PropTypes.string,
  }),
  clientPanel: PropTypes.shape({
    headlineText: PropTypes.string,
    bodyText: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
    image: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  brandingStyles: PropTypes.shape({
    clientPanel: PropTypes.shape({
      clientHeader: PropTypes.string,
      clientBody: PropTypes.string,
    }),
  }),
  redirectURL: PropTypes.func.isRequired,
  locale: PropTypes.string,
  setBrandingStyles: PropTypes.func,
  isBrandingStylesLoaded: PropTypes.bool,
  setBrandingStylesLoaded: PropTypes.func,
};

export default MainPanel;
