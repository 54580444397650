import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import {
  getDevicePrint,
  sleep,
  SECURE,
  setCookie,
  pendoFidoTracking,
  anonymousPendoUser,
} from '../../util/misc';
import { constants } from './login-comp-constants';

const {
  SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS,
  DYNATRACE_COOKIE,
  DYNATRACE_COOKIE_EXPIRY,
} = constants;

const GreetingsFirst = (props) => {
  const {
    userid,
    userFName,
    verifyUserid,
    useridVerified,
    currentlySending,
    isAppView,
    isFidoAuthRequired,
  } = props;

  const SLEEP_REFETCH_DEVICEPRINT_MS =
    (window.env && window.env.SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS) ||
    SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS;

  let devicePrint = getDevicePrint();

  // eslint-disable-next-line max-len

  useEffect(() => {
    pendoFidoTracking(userid);
    if (!useridVerified) {
      if (devicePrint === '') {
        anonymousPendoUser();
        (async () => {
          await sleep(SLEEP_REFETCH_DEVICEPRINT_MS);
          devicePrint = getDevicePrint();
          !currentlySending && verifyUserid(devicePrint);
        })();
      } else {
        anonymousPendoUser();
        !currentlySending && verifyUserid(devicePrint);
      }
      setCookie(DYNATRACE_COOKIE, userid, SECURE, DYNATRACE_COOKIE_EXPIRY);
    }
  }, []);

  return isAppView ? (
    <h2 className="text-4xl font-black overflow-ellipsis overflow-hidden my-4">
      <FormattedMessage
        id="signin_hello_user"
        defaultMessage="Hello,"
      />
      <span>
        { ' ' }
        { userFName }
      </span>
    </h2>
  ) : (
    <>
      <div className="text-lg flex w-xs justify-center">
        <FormattedMessage
          id="signin_hello_user"
          defaultMessage="Hello,"
        />
        <span className="overflow-ellipsis overflow-hidden">
          &nbsp;
          { userFName }
          .
        </span>
      </div>
      <div
        className="text-lg flex w-xs justify-center"
        hidden={ isFidoAuthRequired }
      >
        <div
          slot="label"
          className="mb-6 text-lg whitespace-nowrap"
        >
          <FormattedMessage
            id="signin.userid"
            defaultMessage="User ID"
            description="User ID label"
          />
          :
          <span className="p-2">{ userid }</span>
        </div>
      </div>
    </>
  );
};

GreetingsFirst.propTypes = {
  userid: propTypes.string.isRequired,
  userFName: propTypes.string.isRequired,
  verifyUserid: propTypes.func,
  currentlySending: propTypes.bool,
  isAppView: propTypes.bool,
  useridVerified: propTypes.bool,
  isFidoAuthRequired: propTypes.bool,
};

export default GreetingsFirst;
