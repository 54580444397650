import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SdfButton } from '@waypoint/react-components';
import { isEnterKeyPressed } from '../../util/misc';

const ResendConfirmationPage = (props) => {
  const {
    toggleResendConfirmationPage, resendPasscode, currentlySending, isAppView,
  } = props;

  return (
    <div id="resend-confirmation-page">
      <div
        id="resend_passcode_label_id"
        className="font-medium md:font-normal leading-md mt-2"
      >
        <p>
          <FormattedMessage
            id="channelCollection_resend_otp_message_first"
            defaultMessage="This will invalidate the initial verification code we sent and create a new one."
          />
          <br />
          <FormattedMessage
            id="channelCollection_resend_otp_message_second"
            defaultMessage="Do you wish to continue?"
          />
        </p>
      </div>
      <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
        <SdfButton
          id="resend-passcode"
          class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto mt-2 md:mt-0' }
          size="lg"
          emphasis="primary"
          onClick={ () => {
            !currentlySending && resendPasscode();
          } }
          onKeyPress={ (evt) => {
            !currentlySending && isEnterKeyPressed(evt.key) && resendPasscode();
          } }
        >
          <FormattedMessage
            id="channelCollection_resend_otp_button"
            defaultMessage="Yes, resend code"
          />
        </SdfButton>
        <SdfButton
          id="cancel-resend"
          class={ isAppView ? 'w-full' : 'max-w-xs w-full md:w-auto mt-0 md:mt-0' }
          size="lg"
          emphasis="secondary"
          onClick={ () => {
            toggleResendConfirmationPage(false);
          } }
          onKeyPress={ (evt) => {
            isEnterKeyPressed(evt.key) && toggleResendConfirmationPage(false);
          } }
        >
          <FormattedMessage
            id="general_no"
            defaultMessage="No"
            description="Resend No button"
          />
        </SdfButton>
      </div>
    </div>
  );
};

ResendConfirmationPage.propTypes = {
  toggleResendConfirmationPage: PropTypes.func,
  resendPasscode: PropTypes.func,
  currentlySending: PropTypes.bool,
  isAppView: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};
export default ResendConfirmationPage;
