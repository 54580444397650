/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable global-require */
/* eslint-disable prefer-const */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
  SdfCheckbox, SdfButton, SdfInput, SdfTooltip,
} from '@waypoint/react-components';
import {
  setCookie,
  getCookie,
  removeCookie,
  getParameterByName,
  extractBrandingImageProperty,
  getReturnUrl,
  getDevicePrint,
  sleep,
  isLocalStorageSupported,
  removeFidoLocalStorage,
  setController,
  pendoOnClick,
  SECURE,
  checkNnlToggle,
  anonymousPendoUser,
  SPI_CLIENT_NAME,
  SPI_MOBI_FED_URL,
  ImageLinkLogo,
  setFocusOnInput,
  adpDefaultLogo,
  trackEvents,
  checkFidoAuthRequired,
  isNotEmpty,
} from '../../util/misc';
import { constants } from './login-comp-constants';
import { ALERT_TYPE_WARNING } from '../../layouts/layout-constants';
import { Controller } from '../fido/Controller';
import { AlertMessage, CommonAlert } from '..';
import ForgotUserIdLink from '../common/ForgotUserIdLink';
import GreetingsFirst from './GreetingsFirst';

import {
  ADP_LANG_LOCALE_COOKIE,
  DEFAULT_LOCALE,
  getDefaultLocaleFromPreferences,
  getLocaleObjFromPropKey,
} from '../../util/locale';
import UserTypeMessage from '../common/UserTypeMessage';
import SPIForm from './SPIForm';
import { SWITCH_TO_PWD_ACTION } from '../../util/pendo/constants';

/* TO-DO: CALLING_APP_ID, RETURN_URL, */
const {
  CHALLENGE_TYPE_PASSKEY,
  CHALLENGE_TYPE_PASSWORD,
  ENTER_KEY_CODE,
  ALP_COOKIE_REMEMBER_ME,
  ADP_USER_ID_COOKIE,
  COOKIE_REMEMBER_ME,
  DYNATRACE_COOKIE,
  COOKIE_GREET_FNAME,
  CALLING_APP_ID_BROWSER_LABEL,
  POST,
  SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS,
  newUser,
  signDiffUser,
  DYNATRACE_COOKIE_EXPIRY,
  FIDO2REGISTERED,
  SPINFO_COOKIE,
} = constants;

const isNotBlank = value => value !== undefined && value !== null && value.trim() !== '';
const isBlank = str => str === undefined || (str || '').trim().length === 0;

let controller = null;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.usernameRef = React.createRef();
    this.previousInputRef = false;
    this.previousUsernameRef = false;
    this.onChecked = this.onChecked.bind(this);
    if (controller === null && checkNnlToggle()) {
      controller = new Controller();
      controller.initialize('fido2', 'native');
      setController(controller);
    }
  }

  componentDidMount() {
    const {
      resetState,
      setUserid,
      userid,
      changeUserFname,
      setRememberCheckBox,
      changeAdpUserId,
      setIsADPUser,
      validationError,
      rememberCheckBox,
      switchToPassword,
      setSwitchPassword,
    } = this.props;

    const isPasswordSwitched = switchToPassword;

    resetState();

    if (isPasswordSwitched) {
      setSwitchPassword(true);
    }

    const rememberUserIdCookie = getCookie(COOKIE_REMEMBER_ME) ?
      getCookie(COOKIE_REMEMBER_ME) :
      getCookie(ALP_COOKIE_REMEMBER_ME);

    changeAdpUserId(rememberUserIdCookie);

    const spiCookieData = getCookie(SPINFO_COOKIE);

    if (spiCookieData) {
      const spInfo = spiCookieData.split('~');

      localStorage.setItem(SPI_CLIENT_NAME, spInfo[0]);
      localStorage.setItem(SPI_MOBI_FED_URL, spInfo[1]);
      removeCookie(SPINFO_COOKIE);
    }

    const userFName = getCookie(COOKIE_GREET_FNAME);

    changeUserFname(userFName);

    if (userid) {
      setRememberCheckBox(rememberCheckBox || !!rememberUserIdCookie);
      if (validationError === 'signin.adpRemember') {
        setIsADPUser(true);
      }
    } else if (rememberUserIdCookie) {
      if (
        rememberUserIdCookie
          .toString()
          .toLowerCase()
          .endsWith('@adp')
      ) {
        setUserid('');
      } else {
        setRememberCheckBox(true);
        setUserid(rememberUserIdCookie.toString());
      }
    }
    const userIdFromExtApp = getCookie(ADP_USER_ID_COOKIE);

    if (userIdFromExtApp) {
      setUserid(userIdFromExtApp);
      removeCookie(ADP_USER_ID_COOKIE);
    }
    let inputEl = document.getElementById('login-form_username');

    inputEl && setFocusOnInput(inputEl);
  }

  componentDidUpdate() {
    if (this.previousUsernameRef && this.usernameRef.current) {
      this.usernameRef.current.setFocus();
      this.previousUsernameRef = false;
    }

    if (this.previousInputRef && this.inputRef.current) {
      this.inputRef.current.setFocus();
      this.previousInputRef = false;
    }

    if (this.usernameRef.current && isNotEmpty(this.usernameRef.current.value)) {
      this.usernameRef.current.removeAttribute('autocomplete');
    }
  }

  onChecked = (val) => {
    const {
      userid, setValidationError, setIsADPUser, setRememberCheckBox,
    } = this.props;

    if (userid && userid.toLowerCase().endsWith('@adp')) {
      setRememberCheckBox(false);
      setIsADPUser(true);
      setValidationError('signin.adpRemember');
    } else {
      setRememberCheckBox(!!val.detail);
      setIsADPUser(false);
      setValidationError('');
    }
    if (!val.detail) {
      removeCookie(COOKIE_REMEMBER_ME);
    }
  };

  render() {
    const {
      userid,
      password,
      currentlySending,
      verifyUserid,
      setUserid,
      useridVerified,
      setPassword,
      authenticate,
      authenticateFido2,
      challengeType,
      background,
      productLogo,
      organizationLogo,
      redirectURL,
      identityProviders,
      setValidationError,
      rememberCheckBox,
      isADPUser,
      setIsADPUser,
      setRememberCheckBox,
      locale,
      adpUserId,
      setSwitchPassword,
      switchToPassword,
      sendingRequest,
      userFName,
      changeAdpUserId,
      changeUserFname,
      changeLocale,
      defaultLocaleMode,
      language,
      formRedirectURL,
      useBrowserIfNoCookie,
      passwordRecovery,
      clearChangePasswordState,
      toggleNextBtn,
      intl,
      text,
      isMobileDevice,
      fidoAuthEligible,
      diffUserAction,
      empTargetUrl,
      adminTargetUrl,
    } = this.props;

    const SLEEP_REFETCH_DEVICEPRINT_MS =
      (window.env && window.env.SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS) ||
      SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS;

    const clearReduxState = () => clearChangePasswordState();

    const isValidUserid = !currentlySending && isNotBlank(userid);

    let devicePrint = getDevicePrint();

    const productLogoLinkUrl =
      productLogo && productLogo.linkUrl ?
        getLocaleObjFromPropKey(productLogo.linkUrl, locale) :
        '';

    const { url: identityRecoveryUrl, version } =
      identityProviders && identityProviders[0].identifierRecovery ?
        identityProviders[0].identifierRecovery :
        '';

    const isFidoAuthRequired = checkFidoAuthRequired(userid, switchToPassword) && fidoAuthEligible;

    const passkeyNonGreetingsCheck = fidoAuthEligible && (isBlank(adpUserId) || isBlank(userFName));

    const onSwitchPassword = () => {
      trackEvents(SWITCH_TO_PWD_ACTION);
      setSwitchPassword(true);
      onVerifyUserid();
    };
    const onVerifyUserid = () => {
      if (devicePrint === '') {
        anonymousPendoUser();
        (async () => {
          await sleep(SLEEP_REFETCH_DEVICEPRINT_MS);
          devicePrint = getDevicePrint();
          userid && !currentlySending && verifyUserid(devicePrint);
        })();
      } else {
        anonymousPendoUser();
        userid && !currentlySending && verifyUserid(devicePrint);
      }

      setCookie(DYNATRACE_COOKIE, userid, SECURE, DYNATRACE_COOKIE_EXPIRY);
    };

    const handleKeyPressOnNexIn = (event) => {
      if (event.charCode === ENTER_KEY_CODE) {
        event.preventDefault();
        onVerifyUserid();
        this.previousInputRef = true;
      }
    };

    const handleKeyPressOnSignIn = (event) => {
      if (event.charCode === ENTER_KEY_CODE) {
        event.preventDefault();
        onAuthentication();
      }
    };

    if (useridVerified) {
      let passwordEl = document.getElementById('login-form_password');

      setFocusOnInput(passwordEl);
    }
    if (defaultLocaleMode) {
      const defaultAdpLocaleLangCookie = {
        key: 'AdpLangCookie',
        value: getCookie(ADP_LANG_LOCALE_COOKIE),
      };
      const defaultBrowserLocale = {
        key: 'Browser',
        value: navigator.language || navigator.browserLanguage,
      };
      const defaultBrandingLocale = {
        key: 'branding',
        value: language.default,
      };
      const defaultOlp = { key: 'defaultOLP', value: DEFAULT_LOCALE };
      const defaultLocalePreferences = [
        defaultAdpLocaleLangCookie,
        defaultBrandingLocale,
        defaultOlp,
      ];
      const defaultLocalePreferencesWithBrowser = [
        defaultAdpLocaleLangCookie,
        defaultBrandingLocale,
        defaultBrowserLocale,
        defaultOlp,
      ];

      // eslint-disable-next-line no-nested-ternary
      const localeParamsPreferences = useBrowserIfNoCookie ?
        defaultLocalePreferencesWithBrowser :
        defaultLocalePreferences;

      const defaultLocalePrefer = getDefaultLocaleFromPreferences(
        localeParamsPreferences,
        language.options,
      );

      changeLocale(defaultLocalePrefer.value, true);
    }

    const isValidPassword = !currentlySending && isNotBlank(password);

    const onAuthentication = () => {
      clearReduxState();
      if (isFidoAuthRequired) {
        pendoOnClick(userid, locale, version);
        !sendingRequest && authenticateFido2();
      } else {
        !currentlySending && userid && isValidPassword && authenticate();
      }
      removeCookie(DYNATRACE_COOKIE);
    };
    const returnURLFromSignInAPI =
      identityProviders && identityProviders.length > 0 ? identityProviders[0].returnUrl : '';
    const returnURL = getReturnUrl() || returnURLFromSignInAPI;
    const CALLING_APP_ID = getParameterByName(CALLING_APP_ID_BROWSER_LABEL);
    const bgImage = extractBrandingImageProperty(background, locale);
    const productLogoImage = extractBrandingImageProperty(productLogo, locale);
    const organizationLogoImage = extractBrandingImageProperty(organizationLogo, locale);

    const signAsDiffUser = { ...signDiffUser };

    signAsDiffUser.hyperLinkIDPre = signDiffUser.hyperLinkIDPre;
    signAsDiffUser.hyperLinkdefaultMessagePre = signDiffUser.hyperLinkdefaultMessagePre;

    const typeOfUser = userFName ? signAsDiffUser : newUser;

    const { signUp } =
      identityProviders && identityProviders.length > 0 ? identityProviders[0] : '';
    const signUpAlt = { url: signUp && signUp.url, method: POST };
    const signUpObject = signUp && signUp.method ? signUp : signUpAlt;

    const { url: passwordRecoveryUrl, version: passwordRecoveryVersion } = passwordRecovery || '';

    const passwordRecoveryVersionString =
      passwordRecoveryVersion && passwordRecoveryVersion.toString();

    const getVersion = () => {
      if (useridVerified) {
        return isNotBlank(passwordRecoveryVersionString) ? passwordRecoveryVersion : version;
      }
      return version;
    };

    const checkADPUser = () => {
      if (!userid.toLowerCase().endsWith('@adp')) {
        setIsADPUser(false);
        setValidationError('');
      }
      if (userid.toLowerCase().endsWith('@adp') && rememberCheckBox) {
        setIsADPUser(true);
        setRememberCheckBox(false);
      }
    };

    const spiClientName = localStorage.getItem(SPI_CLIENT_NAME);
    const spiMobiFedURL = localStorage.getItem(SPI_MOBI_FED_URL);
    const isSPIFlow =
      isLocalStorageSupported(true) && isNotBlank(spiClientName) && isNotBlank(spiMobiFedURL);

    const { i18n } = text;
    const signInHomeTitle = getLocaleObjFromPropKey(i18n, locale) ?
      getLocaleObjFromPropKey(i18n, locale).SIGN_IN_HOME_TITLE :
      intl.formatMessage({ id: 'welcome.title', defaultMessage: 'Welcome to ADP' });

    const getRememberUserContent = () => intl.formatMessage({
      id: 'signin.publicRemember',
      defaultMessage: 'Do not store your user ID here if this device is used by others.',
    });

    const onSelectDiffUser = () => {
      this.previousUsernameRef = true;
    };

    return (
      <div className="flex flex-col justify-center h-full">
        <div className={ isSPIFlow ? 'min-h-xs' : 'min-h-sm' }>
          <div className="text-center mt-6 md:mt-0">
            { !organizationLogoImage ? (
              <div className="flex justify-center">
                { productLogoLinkUrl ? (
                  <a href={ productLogoLinkUrl }>{ ImageLinkLogo(productLogoImage) }</a>
                ) : (
                  ImageLinkLogo(productLogoImage)
                ) }
              </div>
            ) : (
              <div className="flex justify-center">{ adpDefaultLogo() }</div>
            ) }
            <h2 className="font-bold text-2xl mt-8">{ signInHomeTitle }</h2>
          </div>
          <div className="mb-4">
            <AlertMessage { ...this.props } />
          </div>
          <div className={ !isSPIFlow ? 'mx-auto my-0 flex flex-col items-center' : '' }>
            { isSPIFlow ? (
              <SPIForm
                id="spiForm"
                userFName={ userFName }
                clientName={ spiClientName }
                mobiFedURL={ spiMobiFedURL }
                redirectURL={ redirectURL }
              />
            ) : (
              <>
                { !(adpUserId && userFName) ? (
                  <>
                    <div className="mb-4 max-w-xs w-full">
                      <form>
                        <SdfInput
                          id="login-form_username"
                          value={ userid }
                          label={ intl.formatMessage({
                            id: 'signin.userid',
                            defaultMessage: 'User ID',
                          }) }
                          ref={ this.usernameRef }
                          className="w-full"
                          onSdfInput={ eve => setUserid(eve.detail) }
                          onKeyPress={ handleKeyPressOnNexIn }
                          onBlur={ checkADPUser }
                          autofocus
                          autocomplete="username"
                        />
                      </form>
                      <div className="mt-4 flex max-w-xs">
                        <SdfCheckbox
                          id="user-remember-checkbox"
                          checked={ rememberCheckBox }
                          onSdfChange={ this.onChecked }
                          disabled={ isADPUser || undefined }
                        >
                          <FormattedMessage
                            id="signin.rememberUserId"
                            defaultMessage="Remember user ID"
                            description="remember me label"
                          />
                        </SdfCheckbox>
                        <SdfTooltip
                          className="flex text-lg ms-1"
                          tooltip-id="signin.remembercheckboxtooltip"
                          state="neutral"
                          attachment-point="top"
                          trigger="click"
                          position-context="element"
                        >
                          <SdfButton
                            id="signin.remembercheckboxBtn"
                            slot="tooltip-target"
                            icon="action-help"
                            variant="text"
                            class="text-action-primary"
                            icon-only
                          />
                          { intl.formatMessage({
                            id: 'signin.rememberPopOverText',
                            defaultMessage: 'More Information about remember me',
                            description: 'More Information about remember me label for mouseover',
                          }) }
                        </SdfTooltip>
                      </div>
                    </div>
                    { rememberCheckBox &&
                      !isMobileDevice &&
                      !(getCookie(COOKIE_REMEMBER_ME) || getCookie(ALP_COOKIE_REMEMBER_ME)) && (
                        <div
                          id="frmpublicRememberWarningMessage"
                          className="mt-1"
                        >
                          <CommonAlert
                            autoClose={ false }
                            alertType={ ALERT_TYPE_WARNING }
                            content={ getRememberUserContent() }
                          />
                        </div>
                    ) }
                  </>
                ) : (
                  <GreetingsFirst
                    userid={ userid }
                    userFName={ userFName }
                    verifyUserid={ verifyUserid }
                    useridVerified={ useridVerified }
                    switchToPassword={ switchToPassword }
                    formattedNumber="(...3456)"
                    isFidoAuthRequired={ isFidoAuthRequired }
                  />
                ) }
                { // eslint-disable-next-line max-len
                useridVerified && challengeType !== CHALLENGE_TYPE_PASSKEY && (
                  <div
                    id="frmPassword"
                    className="max-w-xs w-full"
                  >
                    <form>
                      <SdfInput
                        id="login-form_password"
                        autocomplete="current-password"
                        autofocus={ useridVerified }
                        ref={ this.inputRef }
                        value={ password }
                        label={ intl.formatMessage({
                          id: 'signin.password',
                          defaultMessage: 'Password',
                        }) }
                        type="password"
                        onSdfInput={ eve => setPassword(eve.detail) }
                        onKeyPress={ handleKeyPressOnSignIn }
                        className="w-full"
                        style={ { minHeight: '7rem' } }
                      />
                    </form>
                  </div>
                ) }
                { challengeType === CHALLENGE_TYPE_PASSKEY && adpUserId && userFName && (
                  <div
                    id="fido_device_login"
                    className="text-center text-lg font-normal"
                    hidden={ !isFidoAuthRequired }
                  >
                    <FormattedMessage
                      id="fido_device_login"
                      defaultMessage="Passkey sign-in is enabled on this device."
                      description="Passkey sign-in is enabled on this device."
                    />
                  </div>
                ) }
              </>
            ) }
          </div>
        </div>
        <div>
          { !isSPIFlow && (
            <div className="flex md:justify-between md:flex-row flex-col-reverse mb-8 items-center">
              <div className="mt-4 md:mt-0">
                { challengeType === CHALLENGE_TYPE_PASSKEY && (
                  <div
                    id="fido2BtnSwitchToPwd"
                    hidden={ !isFidoAuthRequired || !userFName }
                  >
                    <SdfButton
                      buttonStyle="link"
                      id="fido2_switchToPwd"
                      onClick={ onSwitchPassword }
                      emphasis="tertiary"
                      size="lg"
                      className="w-full max-w-xs md:min-w-32 md:w-auto"
                    >
                      <FormattedMessage
                        id="passcode_switchToPassword"
                        defaultMessage="SWITCH TO PASSWORD"
                      />
                    </SdfButton>
                  </div>
                ) }
                { isNotBlank(identityRecoveryUrl) && isLocalStorageSupported(true) && !userFName && (
                  <div
                    id="forgot_uid_link"
                    hidden={
                      useridVerified ?
                        challengeType && challengeType !== CHALLENGE_TYPE_PASSKEY :
                        false
                    }
                  >
                    <ForgotUserIdLink
                      id="forgotUIDLink"
                      key="forgotUIDLink"
                      hyperLinkDescription="Need help signing in?"
                      hyperLinkID="signin.needHelp"
                      hyperLinkdefaultMessage="Need help signing in?"
                      actionURL={ identityRecoveryUrl }
                      callingAppId={ CALLING_APP_ID }
                      backgroundImageUrl={ bgImage }
                      returnUrl={ returnURL }
                      productLogoUrl={ productLogoImage }
                      organizationLogoUrl={ organizationLogoImage }
                      version={ getVersion() }
                      formRedirectURL={ formRedirectURL }
                      locale={ locale }
                      redirectURL={ redirectURL }
                      empTargetUrl={ empTargetUrl }
                      adminTargetUrl={ adminTargetUrl }
                      intl={ intl }
                    />
                  </div>
                ) }
                { isNotBlank(passwordRecoveryUrl) &&
                  isLocalStorageSupported(true) &&
                  !isFidoAuthRequired && (
                    <div
                      id="forgot_pwd_link"
                      hidden={ !useridVerified }
                    >
                      <ForgotUserIdLink
                        id="forgotPwdLink"
                        key="forgotPwdLink"
                        hyperLinkDescription="Need help signing in?"
                        hyperLinkID="signin.needHelp"
                        hyperLinkdefaultMessage="Need help signing in?"
                        actionURL={ passwordRecoveryUrl }
                        userId={ userid }
                        callingAppId={ CALLING_APP_ID }
                        backgroundImageUrl={ bgImage }
                        returnUrl={ returnURL }
                        productLogoUrl={ productLogoImage }
                        organizationLogoUrl={ organizationLogoImage }
                        version={ getVersion() }
                        formRedirectURL={ formRedirectURL }
                        locale={ locale }
                        redirectURL={ redirectURL }
                        empTargetUrl={ empTargetUrl }
                        adminTargetUrl={ adminTargetUrl }
                        intl={ intl }
                      />
                    </div>
                ) }
              </div>
              { isLocalStorageSupported(true) &&
                (!isFidoAuthRequired || !userFName) &&
                toggleNextBtn && (
                  <SdfButton
                    id="verifUseridBtn"
                    data-testid="verifUseridBtn"
                    emphasis="primary"
                    size="lg"
                    hidden={ useridVerified }
                    className="w-full max-w-xs md:min-w-32 md:w-auto"
                    disabled={ !isValidUserid || sendingRequest || undefined }
                    onClick={ onVerifyUserid }
                    onKeyPress={ handleKeyPressOnNexIn }
                    button-title={ intl.formatMessage({ id: 'sigin.next', defaultMessage: 'Next' }) }
                  >
                    <span className="px-4 py-3 text-xl">
                      <FormattedMessage
                        id="sigin.next"
                        defaultMessage="Next"
                        description="next button"
                      />
                    </span>
                  </SdfButton>
              ) }
              { isLocalStorageSupported(true) &&
                (challengeType === CHALLENGE_TYPE_PASSKEY ||
                  challengeType === CHALLENGE_TYPE_PASSWORD) && (
                  <SdfButton
                    id="signBtn"
                    data-testid="signBtn"
                    emphasis="primary"
                    size="lg"
                    className="w-full max-w-xs md:min-w-32 md:w-auto"
                    disabled={
                      (!isValidPassword && !isFidoAuthRequired) || sendingRequest || undefined
                    }
                    onClick={ onAuthentication }
                    onKeyPress={ handleKeyPressOnSignIn }
                    hidden={ !useridVerified && passkeyNonGreetingsCheck }
                    button-title={ intl.formatMessage({
                      id:
                        isFidoAuthRequired && userFName && challengeType === CHALLENGE_TYPE_PASSKEY ?
                          'signin.passkey' :
                          'signin.signin',
                      defaultMessage: 'Sign In',
                    }) }
                  >
                    <span className="px-4 py-3 text-xl">
                      <FormattedMessage
                        id={
                          isFidoAuthRequired &&
                          userFName &&
                          challengeType === CHALLENGE_TYPE_PASSKEY ?
                            'signin.passkey' :
                            'signin.signin'
                        }
                        defaultMessage="Sign In"
                        description="signin button"
                      />
                    </span>
                  </SdfButton>
              ) }
            </div>
          ) }
          { isNotBlank(signUpObject.url) && !userFName && (
            <div id="newUsersignUpButton">
              <hr className="border-0 border-t-2 border-solid border-dark" />
              <UserTypeMessage
                id="signupButton"
                key="signupButton"
                signUp={ signUpObject }
                hyperLink={ typeOfUser }
                returnURL={ returnURL }
                callingAppID={ CALLING_APP_ID }
                backgroundImage={ bgImage }
                qrCodeGreet={ removeFidoLocalStorage() }
                productLogo={ productLogoImage }
                setUserid={ setUserid }
                setRememberCheckBox={ setRememberCheckBox }
                organizationLogo={ organizationLogoImage }
                formRedirectURL={ formRedirectURL }
                empTargetUrl={ empTargetUrl }
                adminTargetUrl={ adminTargetUrl }
                intl={ intl }
              />
            </div>
          ) }
          { userFName && (
            <div id="diffUsersButton">
              <hr className="mt-3 mb-6 border-0 border-t-2 border-solid border-dark" />
              <UserTypeMessage
                id="diffUserButton"
                key="diffUserButton"
                hyperLink={ typeOfUser }
                changeAdpUserId={ changeAdpUserId }
                changeUserFname={ changeUserFname }
                userFName={ userFName }
                setRememberCheckBox={ setRememberCheckBox }
                setUserid={ setUserid }
                diffUserAction={ diffUserAction }
                onSelectDiffUser={ onSelectDiffUser }
                intl={ intl }
              />
            </div>
          ) }
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  userid: PropTypes.string,
  password: PropTypes.string,
  rememberCheckBox: PropTypes.bool,
  isADPUser: PropTypes.bool,
  currentlySending: PropTypes.bool,
  verifyUserid: PropTypes.func.isRequired,
  setUserid: PropTypes.func.isRequired,
  useridVerified: PropTypes.bool,
  setPassword: PropTypes.func.isRequired,
  changeAdpUserId: PropTypes.func,
  setSwitchPassword: PropTypes.func,
  switchToPassword: PropTypes.bool,
  adpUserId: PropTypes.string,
  changeUserFname: PropTypes.func,
  userFName: PropTypes.string,
  clearChangePasswordState: PropTypes.func.isRequired,
  setRememberCheckBox: PropTypes.func.isRequired,
  setIsADPUser: PropTypes.func.isRequired,
  authenticate: PropTypes.func.isRequired,
  authenticateFido2: PropTypes.func.isRequired,
  challengeType: PropTypes.string,
  useBrowserIfNoCookie: PropTypes.bool,
  resetState: PropTypes.func.isRequired,
  background: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  organizationLogo: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  productLogo: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  redirectURL: PropTypes.func.isRequired,
  identityProviders: PropTypes.arrayOf(
    PropTypes.shape({
      signUp: PropTypes.shape({
        url: PropTypes.string,
        method: PropTypes.string,
      }),
      identifierRecoveryUrl: PropTypes.string,
      identifierRecovery: PropTypes.shape({
        url: PropTypes.string,
        version: PropTypes.number,
      }),
    }),
  ),
  passwordRecovery: PropTypes.shape({
    url: PropTypes.string,
    version: PropTypes.number,
  }),
  validationError: PropTypes.string,
  sendingRequest: PropTypes.bool,
  setValidationError: PropTypes.func.isRequired,
  locale: PropTypes.string,
  changeLocale: PropTypes.func.isRequired,
  formRedirectURL: PropTypes.func.isRequired,
  defaultLocaleMode: PropTypes.bool.isRequired,
  language: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  fidoPromptStatus: PropTypes.string,
  isMobileDevice: PropTypes.bool,
  toggleNextBtn: PropTypes.bool,
  diffUserAction: PropTypes.func,
  empTargetUrl: PropTypes.string,
  adminTargetUrl: PropTypes.string,
};

LoginForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LoginForm);
