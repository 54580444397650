import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SdfButton } from '@waypoint/react-components';
import { buildFedTargetUrl } from '../../util/misc';

const Entities = require('html-entities').AllHtmlEntities;
const HTMLReactParser = require('html-react-parser');

const entities = new Entities();

const SPIForm = (props) => {
  const parseHtml = html => HTMLReactParser(html);
  const {
    intl, userFName, clientName, mobiFedURL, redirectURL,
  } = props;

  const targetURL = `${mobiFedURL}?run=true&adpwf=true&fedTarget=${buildFedTargetUrl()}`;

  const buildSPIContinueText = () => intl
    .formatMessage({
      id: 'spi_continue_headline',
      defaultMessage: 'Click Continue to use your credentials to access ADP.',
    })
    .replace('[X]', `<span class="font-bold">${clientName}</span>`);

  const handleRedirect = () => {
    targetURL && redirectURL(targetURL);
  };

  return (
    <>
      <div className="text-lg flex justify-center mb-2">
        <FormattedMessage
          id="signin_hello_user"
          defaultMessage="Hello,"
        />
        <span className="overflow-ellipsis overflow-hidden">
          &nbsp;
          { userFName }
        </span>
      </div>
      <div className="text-center text-lg">
        { parseHtml(entities.decode(buildSPIContinueText())) }
      </div>
      <div className="flex md:justify-end justify-center">
        <SdfButton
          id="spi_continue_button"
          data-testid="spi_continue_button"
          emphasis="primary"
          className="w-full max-w-xs md:min-w-32 md:w-auto mt-20 mb-4"
          size="lg"
          onClick={ handleRedirect }
        >
          <span className="px-4 py-3 text-xl">
            <FormattedMessage
              id="olp_continue"
              defaultMessage="Continue"
            />
          </span>
        </SdfButton>
      </div>
    </>
  );
};

SPIForm.propTypes = {
  userFName: PropTypes.string,
  clientName: PropTypes.string.isRequired,
  mobiFedURL: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  redirectURL: PropTypes.func.isRequired,
};

export default injectIntl(SPIForm);
