/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { SdfAlert, SdfButton } from '@waypoint/react-components';

const Entities = require('html-entities').AllHtmlEntities;
const HTMLReactParser = require('html-react-parser');

const entities = new Entities();

const CommonAlert = (props) => {
  const {
    content,
    alertType,
    autoClose,
    formRedirectURL,
    params,
    alertLinkText,
    isAppView,
  } = props;
  const closeable = true;
  const parseHtml = html => HTMLReactParser(html, {
    // eslint-disable-next-line consistent-return
    replace: (domNode) => {
      if (domNode.name === 'button') {
        // eslint-disable-next-line no-param-reassign
        delete domNode.attribs.onclick;
        return (
          <SdfButton
            { ...domNode.attribs }
            onClick={ () => {
              formRedirectURL(params);
            } }
            id="alertLink"
            data-testid="alertLink"
            buttonStyle="link"
            class="lowercase p-0 text-sm"
          >
            { alertLinkText }
          </SdfButton>
        );
      }
    },
  });

  const alertContent = isAppView ? content : parseHtml(entities.decode(content));

  return (
    <div
      id="common_alert_div"
      className="mb-4"
    >
      <SdfAlert
        type="banner"
        autoClose={ autoClose }
        title={ alertContent }
        closeable={ closeable }
        size="sm"
        id="common_alert"
        status={ alertType }
      >
        <div className="mt-1 font-normal text-sm leading-sm">{ alertContent }</div>
      </SdfAlert>
    </div>
  );
};

CommonAlert.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  formRedirectURL: PropTypes.string,
  alertLinkText: PropTypes.string,
  params: PropTypes.string,
  alertType: PropTypes.string,
  autoClose: PropTypes.bool,
  isAppView: PropTypes.bool,
};

export default CommonAlert;
