/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import { SdfButton, SdfIcon } from '@waypoint/react-components';
import { injectIntl } from 'react-intl';
import { getLocaleObjFromPropKey } from '../../util/locale';
import { startCoBrowseSession } from '../../util/misc';

const CoBrowseComponent = ({
  displayOrder, displayText, locale, intl,
}) => (
  <div id="cobrowse_div">
    <SdfButton
      variant="text"
      className="text-md md:ml-6 mt-4 md:mt-0"
      id={ `cobrowse${displayOrder}` }
      onClick={ startCoBrowseSession }
      buttont-title={ getLocaleObjFromPropKey(displayText, locale) }
    >
      <SdfIcon
        icon="action-chat-person"
        icon-title={ intl.formatMessage({
          id: 'cobrowse_icon_title',
          defaultMessage: 'Chat with a Client support',
        }) }
        className="pr-1"
      />
      { getLocaleObjFromPropKey(displayText, locale) }
    </SdfButton>
  </div>
);

CoBrowseComponent.propTypes = {
  displayOrder: PropTypes.number,
  displayText: PropTypes.objectOf(PropTypes.string),
  locale: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(CoBrowseComponent);
