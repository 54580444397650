import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import {
  SdfInput, SdfRadioButton, SdfRadioGroup, SdfAlert,
} from '@waypoint/react-components';
import { constants } from './channel-collection-constants';

import {
  isOnlyBusinessEmailPresent,
  isOnlyPersonalEmailPresent,
  isPersonalandBusinessEmailPresent,
  isPersonalEmailNotPresent,
  isNotEmpty,
} from '../../util/misc';

const { PERSONAL, BUSINESS } = constants;

const setEmail = (item, personalEmailAddress, businessEmailAddress) => {
  if (item === PERSONAL) {
    return personalEmailAddress;
  }
  if (item === BUSINESS) {
    return businessEmailAddress;
  }
  return '';
};

const ChannelCollectionEmail = (props) => {
  const {
    intl,
    setEmailUsageType,
    emailUsageType,
    setEmailAddress,
    personalEmailAddress,
    setPersonalEmailAddress,
    businessEmailAddress,
    channelCollectionChallenge,
    palErrorMessage,
    handleKeyPress,
  } = props;

  const { communications } = channelCollectionChallenge;

  // eslint-disable-next-line
  const showLabelForPersonalEmail =
    !isNotEmpty(communications) ||
    isOnlyPersonalEmailPresent(communications) ||
    (isNotEmpty(communications) &&
      isPersonalEmailNotPresent(communications) &&
      !isOnlyBusinessEmailPresent(communications));
  // eslint-disable-next-line
  const showRadioButtons =
    isPersonalandBusinessEmailPresent(communications) || isOnlyBusinessEmailPresent(communications);
  const isEmailInputDisabled = emailUsageType === BUSINESS;
  const isEmailErrorPresent = palErrorMessage === 'channelcollection_invalid_email_address_label';

  const onSetEmailUsageType = (item) => {
    setEmailUsageType(item);
    setEmailAddress(setEmail(item, personalEmailAddress, businessEmailAddress, communications));
  };
  const setEmailAdd = (email) => {
    setEmailAddress(email);
    setPersonalEmailAddress(email);
  };

  return (
    <div>
      { showRadioButtons && (
        <SdfRadioGroup
          label={ intl.formatMessage({
            id: 'channelcollection_primary_email_label',
            defaultMessage: 'Primary Email Address',
          }) }
          requiredState="required"
          state="normal"
        >
          <SdfRadioButton
            id="radio_business"
            radioGroupValue={ emailUsageType }
            label={ intl.formatMessage({
              id: 'general_work',
              defaultMessage: 'Work',
            }) }
            value={ BUSINESS }
            onSdfInput={ (evt) => {
              onSetEmailUsageType(evt.detail.value);
            } }
            checked={ emailUsageType === BUSINESS ? 'true' : 'false' }
          />
          <strong className="ml-8 -mt-2">{ businessEmailAddress }</strong>
          <SdfRadioButton
            id="radio_personal"
            radioGroupValue={ emailUsageType }
            label={ intl.formatMessage({
              id: 'general_personal',
              defaultMessage: 'Personal',
            }) }
            value={ PERSONAL }
            onSdfInput={ (evt) => {
              onSetEmailUsageType(evt.detail.value);
            } }
            checked={ emailUsageType === PERSONAL ? 'true' : 'false' }
          />
        </SdfRadioGroup>
      ) }
      <div className={ showRadioButtons ? 'mt-2 md:pl-8' : 'mt-2' }>
        <SdfInput
          id="email-id"
          className="w-full"
          value={ personalEmailAddress }
          label={ intl.formatMessage({
            id: 'channelcollection_personal_email_label',
            defaultMessage: 'Personal Email Address',
          }) }
          requiredState="required"
          hideLabel={ !showLabelForPersonalEmail }
          disabled={ isEmailInputDisabled || undefined }
          state={ isEmailErrorPresent ? 'error' : 'normal' }
          onSdfInput={ eve => setEmailAdd(eve.detail) }
          onKeyPress={ handleKeyPress }
        >
          { palErrorMessage && (
            <SdfAlert
              id="palErrorMessage"
              type="inline"
              slot="alert"
              status="error"
            >
              { intl.formatMessage({
                id: palErrorMessage,
                defaultMessage: 'Invalid email address format.',
              }) }
            </SdfAlert>
          ) }
        </SdfInput>
      </div>
    </div>
  );
};

ChannelCollectionEmail.propTypes = {
  intl: intlShape.isRequired,
  setEmailUsageType: PropTypes.func,
  emailUsageType: PropTypes.string,
  setEmailAddress: PropTypes.func,
  setPersonalEmailAddress: PropTypes.func,
  personalEmailAddress: PropTypes.string,
  businessEmailAddress: PropTypes.string,
  channelCollectionChallenge: PropTypes.shape({
    communications: PropTypes.string,
  }),
  palErrorMessage: PropTypes.string,
  handleKeyPress: PropTypes.func,
};

ChannelCollectionEmail.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ChannelCollectionEmail);
