import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SdfPromoBox } from '@waypoint/react-components';
import {
  getReturnUrl,
  getParameterByName,
  extractBrandingImageProperty,
  passwordRecoveryUrlCheck,
} from '../../util/misc';
import ForgotUserIdLink from '../common/ForgotUserIdLink';
import { constants } from '../login/login-comp-constants';

const { CALLING_APP_ID_BROWSER_LABEL } = constants;

const ErrorPage = (props) => {
  const {
    errorCode,
    userid,
    passwordRecovery,
    background,
    productLogo,
    organizationLogo,
    redirectURL,
    formRedirectURL,
    locale,
    identityProviders,
    errorPageProps,
    intl,
    isAppView,
    empTargetUrl,
    adminTargetUrl,
  } = props;

  const {
    promoBoxRef, linkRef, isButtonCheck, arialabel,
  } = errorPageProps;

  const returnURLFromSignInAPI =
    identityProviders && identityProviders.length > 0 ? identityProviders[0].returnUrl : '';
  const returnURL = getReturnUrl() || returnURLFromSignInAPI;
  const CALLING_APP_ID = getParameterByName(CALLING_APP_ID_BROWSER_LABEL);
  const bgImage = extractBrandingImageProperty(background, locale);
  const productLogoImage = extractBrandingImageProperty(productLogo, locale);
  const organizationLogoImage = extractBrandingImageProperty(organizationLogo, locale);

  const { version: passwordRecoveryVersion } = passwordRecovery || '';

  let { url: passwordRecoveryUrl } = passwordRecovery || '';

  const localeData = intl.formatMessage({
    id: promoBoxRef.header,
    defaultMessage: 'Account Error',
  });

  const localemessage = intl.formatMessage({
    id:
      errorCode === 'ACCOUNT_LOCKED' ||
      errorCode === 'ADMIN_ACCOUNT_LOCKED' ||
      errorCode === 'QUESTIONNAIRE_ACCOUNT_LOCKED' ?
        `APP_${errorCode}` :
        errorCode,
  });

  passwordRecoveryUrl =
    passwordRecoveryUrl === '' || passwordRecoveryUrl === undefined ?
      passwordRecoveryUrlCheck(passwordRecoveryUrl) :
      passwordRecoveryUrl;
  return (
    <div
      id="frmError"
      className="content-center"
    >
      <SdfPromoBox
        id="errorPage_layout"
        align="center"
        illustrationName={ promoBoxRef.illustrationName }
        layout="lg"
        size="lg"
        variant="clear"
        spacing="default"
        header={ localeData }
        message={ localemessage }
      />
      <div className={ isAppView ? 'text-center' : 'text-center md:grid md:justify-end mt-6' }>
        { isButtonCheck ? (
          <ForgotUserIdLink
            id={ linkRef.id }
            key={ linkRef.id }
            hyperLinkDescription={ linkRef.msg }
            hyperLinkID={ linkRef.hyperLinkID }
            hyperLinkdefaultMessage={ linkRef.msg }
            actionURL={ passwordRecoveryUrl }
            userId={ userid }
            langPref={ isAppView ? locale : '' }
            oneMobile={ isAppView }
            olpNative={ isAppView }
            callingAppId={ CALLING_APP_ID }
            backgroundImageUrl={ bgImage }
            returnUrl={ returnURL }
            productLogoUrl={ productLogoImage }
            organizationLogoUrl={ organizationLogoImage }
            version={ passwordRecoveryVersion }
            formRedirectURL={ formRedirectURL }
            locale={ locale }
            redirectURL={ redirectURL }
            ariaLabel={ arialabel }
            empTargetUrl={ empTargetUrl }
            adminTargetUrl={ adminTargetUrl }
            intl={ intl }
          />
        ) : (
          ''
        ) }
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  userid: PropTypes.string,
  locale: PropTypes.string,
  isAppView: PropTypes.bool,
  background: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  organizationLogo: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  productLogo: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  passwordRecovery: PropTypes.shape({
    url: PropTypes.string,
    version: PropTypes.number,
  }),
  redirectURL: PropTypes.func.isRequired,
  formRedirectURL: PropTypes.func.isRequired,
  identityProviders: PropTypes.arrayOf(
    PropTypes.shape({
      returnUrl: PropTypes.string,
      identifierRecoveryUrl: PropTypes.string,
      identifierRecovery: PropTypes.shape({
        url: PropTypes.string,
        version: PropTypes.number,
      }),
    }),
  ),
  errorPageProps: PropTypes.shape({
    promoBoxRef: PropTypes.string,
    arialabel: PropTypes.string,
    linkRef: PropTypes.string,
    isButtonCheck: PropTypes.bool,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  empTargetUrl: PropTypes.string,
  adminTargetUrl: PropTypes.string,
};

export default injectIntl(ErrorPage);
