/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SdfButton, SdfPromoBox } from '@waypoint/react-components';
import {
  isMobileCollection,
  isMobileVerification,
  isEmailCollection,
  isEmailVerification,
  isLandlineCollection,
  isVoiceBioMetricsCollection,
  isEnterKeyPressed,
} from '../../util/misc';

const ChannelCollectionError = (props) => {
  const {
    skipChannelCollection,
    currentlySending,
    channelCollectionChallenge,
    intl,
    isAppView,
  } = props;

  const { communicationCollectionType } = channelCollectionChallenge;

  const isEmailFlow =
    !(
      isMobileCollection(communicationCollectionType) ||
      isMobileVerification(communicationCollectionType)
    ) &&
    (isEmailCollection(communicationCollectionType) ||
      isEmailVerification(communicationCollectionType));

  const isMobileFlow =
    (isMobileCollection(communicationCollectionType) ||
      isMobileVerification(communicationCollectionType)) &&
    !(
      isEmailCollection(communicationCollectionType) ||
      isEmailVerification(communicationCollectionType)
    );

  const isLandlineFlow = isLandlineCollection(communicationCollectionType);

  const isVoiceBioMetricsFlow = isVoiceBioMetricsCollection(communicationCollectionType);

  const getErrorMessage = () => {
    if (isMobileFlow) {
      return intl.formatMessage({
        id: 'channelcollection_mobile_warning_headline',
        defaultMessage: 'We could not verify your mobile number at this time.',
      });
    }
    if (isEmailFlow) {
      return intl.formatMessage({
        id: 'channelcollection_email_warning_headline',
        defaultMessage: 'We could not verify your email address at this time.',
      });
    }

    if (isLandlineFlow) {
      return intl.formatMessage({
        id: 'channelcollection_landline_warning_headline',
        defaultMessage: 'We could not verify your phone number at this time.',
      });
    }

    if (isVoiceBioMetricsFlow) {
      return intl.formatMessage({
        id: 'channelcollection_voicebiometrics_warning_headline',
        defaultMessage: 'We could not process your request.',
      });
    }

    return intl.formatMessage({
      id: 'channelcollection_channel_warning_headline',
      defaultMessage: 'We could not verify your contact info at this time.',
    });
  };

  const getFormattedMessage = () => intl.formatMessage({
    id: 'channelcollection_mobile_error_text',
    defaultMessage: "Don't worry. We can try again later.",
  });

  const handleContinue = () => {
    !currentlySending && skipChannelCollection(false);
  };

  const handleContinueButtonClick = () => {
    handleContinue();
  };

  const handleContinueButtonKeyPress = (event) => {
    if (isEnterKeyPressed(event.key)) {
      handleContinue();
    }
  };

  return (
    <div
      id="channel-collection-server-error"
      className="flex flex-col"
    >
      <div className="flex justify-center">
        <SdfPromoBox
          variant="clear"
          align="center"
          size="lg"
          header={ getErrorMessage() }
          message={ getFormattedMessage() }
          illustrationName="clipboard"
        />
      </div>

      <div className="flex md:flex-row md:justify-end gap flex-col items-center mt-4 md:mt-10">
        <SdfButton
          id="skipChannelCollection"
          className={ isAppView ? 'w-full' : 'md:pl-4 max-w-xs w-full md:w-auto' }
          emphasis="primary"
          size="lg"
          onClick={ handleContinueButtonClick }
          onKeyPress={ handleContinueButtonKeyPress }
        >
          <FormattedMessage
            id="general_continue"
            defaultMessage="Continue"
          />
        </SdfButton>
      </div>
    </div>
  );
};

ChannelCollectionError.propTypes = {
  skipChannelCollection: PropTypes.func,
  currentlySending: PropTypes.bool,
  channelCollectionChallenge: PropTypes.shape({
    communicationCollectionType: PropTypes.arrayOf(
      PropTypes.shape({
        promptName: PropTypes.string,
        promptStatus: PropTypes.string,
      }),
    ),
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  isAppView: PropTypes.bool,
};
export default injectIntl(ChannelCollectionError);
