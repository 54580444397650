import React from 'react';
import PropTypes from 'prop-types';
import { SdfButton, SdfFocusPane } from '@waypoint/react-components';
import { getLocaleObjFromPropKey } from '../../util/locale';

class FooterPopUpModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      displayText,
      displayOrder,
      url,
      locale,
      modalType,
      paneType,
      size,
      hideFooter,
      spacing,
    } = this.props;
    const { showModal } = this.state;
    const isSlideIn = modalType === 'slide-in';

    return (
      <>
        <SdfButton
          emphasis="tertiary"
          id={ displayOrder }
          variant="text"
          className="text-md md:ml-6 mt-4 md:mt-0"
          onClick={ this.showModal }
          button-title={ getLocaleObjFromPropKey(displayText, locale) }
        >
          { getLocaleObjFromPropKey(displayText, locale) }
        </SdfButton>

        <SdfFocusPane
          size={ size }
          visible={ showModal }
          closeable
          hideFooter={ hideFooter }
          spacing={ spacing }
          pane-type={ paneType }
          onSdfDismiss={ this.hideModal }
        >
          <div
            slot="title"
            className={ isSlideIn ? 'text' : 'text-center' }
          >
            { getLocaleObjFromPropKey(displayText, locale) }
          </div>

          <iframe
            className={ `w-full ${isSlideIn ? 'h-full border-none' : 'h-md'}` }
            title={ getLocaleObjFromPropKey(displayText, locale) }
            src={ getLocaleObjFromPropKey(url, locale) }
          />
        </SdfFocusPane>
      </>
    );
  }
}

FooterPopUpModal.propTypes = {
  displayText: PropTypes.objectOf(PropTypes.string).isRequired,
  displayOrder: PropTypes.number,
  url: PropTypes.objectOf(PropTypes.string).isRequired,
  locale: PropTypes.string.isRequired,
  modalType: PropTypes.oneOf(['pop-up', 'slide-in']).isRequired,
  paneType: PropTypes.string.isRequired,
  size: PropTypes.string,
  hideFooter: PropTypes.bool,
  spacing: PropTypes.string,
};

export default FooterPopUpModal;
