/* eslint-disable no-undef */
import {
  map, catchError, switchMap, mergeMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';

import { concat, from, of } from 'rxjs';
import { types } from '..';
import { isDarkMode } from '../../../../util/misc';

const {
  LOAD_SYNERGY_COMPONENTS_REQUEST,
  LOAD_SYNERGY_COMPONENTS_SUCCESS,
  LOAD_SYNERGY_COMPONENTS_FAIL,
} = types;

const loadSynergyComponents = createAction(LOAD_SYNERGY_COMPONENTS_REQUEST);
const loadSynergyComponentsSuccess = createAction(LOAD_SYNERGY_COMPONENTS_SUCCESS);
const loadSynergyComponentsFail = createAction(LOAD_SYNERGY_COMPONENTS_FAIL);

export const loadSynergyComponentsEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_SYNERGY_COMPONENTS_REQUEST),
  switchMap(() => from(
    SynergConfig.initialize({ mode: isDarkMode() ? 'one-dark' : 'one-ux' })
      .then(cfg => cfg.loadGlobalStyles(['sdf-global', 'sdf-utilities']))
      .then(_ => console.log('Synergy components are successfully loaded')),
  )),
  mergeMap(() => of(loadSynergyComponentsSuccess())),
  catchError(() => of(loadSynergyComponentsFail())),
);
